import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  FormControl,
} from '@mui/material';
import Logo from '../../assets/VE_LOGO1.png';
import { CommonSevice } from '../../services/common';
import { showToast } from '../../utils/common.util';
import { LoadingStatus, ToastThemes } from '../../constants/common';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Country, City, State } from 'country-state-city';
import ApiSelectTag from '../../atoms/Select/ApiSelectTag';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import FieldView from '../molecules/FieldView/FieldView';

const Subscribe = () => {
  const [formData, setFormData] = useState({
    tenantName: '',
    tenantSubdomain: '',
    adminName: '',
    email: '',
    adminMobileNumber: '',
    country: '',
    city: '',
    state: '',
    product: '',
  });
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [tenantProfileId, setTenantProfileId] = useState(null);
  const [loading, setLoading] = useState(-1);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [products, setProducts] = useState([]);
  const [isVerified, setIsVerified] = useState(false); // Track verification status
  const [loadin, setLoadin] = useState(true);
  const [urlToken, setUrlToken] = useState(
    new URLSearchParams(window.location.search).get('token')
  );
  const [ssoResponse, setSSOResponse] = useState();
  const [capturedData, setCapturedData] = useState({});
  const navigate = useNavigate();

  const RECAPTCHA_SITE_KEY = '6Lc5QqEqAAAAAI2vRoPVBybb2C92StbNYl9U4xmx';
  const msalConfig = {
    auth: {
      clientId: '567d082f-4308-40ae-96a6-dd48b9458cec',
      authority:
        'https://login.microsoftonline.com/e6fdbcc7-dcec-409f-8d1c-0eec6fa68f44',
      redirectUri: `${window.location.origin}/subscribe`, // Redirect URI
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig); // Create MSAL instance

  useEffect(() => {
    const handleVerification = async () => {
      try {
        const response = await msalInstance.handleRedirectPromise();

        if (response) {
          setIsVerified(true);
          setSSOResponse(response);
        } else {
          const accounts = msalInstance.getAllAccounts();
          if (accounts && accounts.length > 0) {
            const tokenResponse = await msalInstance
              .acquireTokenSilent({
                account: accounts[0],
              })
              .catch((error) => console.log(error));
            setIsVerified(true);
            setSSOResponse(tokenResponse);
          } else {
            const x = await msalInstance.loginRedirect();
          }
        }
      } catch (error) {
        console.log('SSO Error:', error);
        showToast('Redirecting to login...', ToastThemes.error);
        await msalInstance.loginRedirect();
      } finally {
        setLoadin(false);
      }
    };
    handleVerification();
  }, [urlToken]);

  useEffect(async () => {
    await new CommonSevice()
      .getProductList()
      .then((res) => {
        const productList = res.map((prod) => ({
          name: prod.prod_name,
          id: prod.pk_prod_id,
        }));
        setProducts(productList);
      })
      .catch((err) => {
        throw err?.response?.data?.message;
      });
  }, []);

  useEffect(async () => {
    if (urlToken && ssoResponse) {
      await new CommonSevice()
        .sendToken(urlToken, ssoResponse?.account?.username)
        .then(async (res) => {
          const { product, ...rest } = res;
          await new CommonSevice().getProductList().then((list) => {
            const productList = list.map((prod) => ({
              name: prod.prod_name,
              id: prod.pk_prod_id,
            }));
            const productId = productList?.find((item) => item?.id === product);
            setCapturedData(res);
            setFormData({ ...formData, ...rest, product: productId });
          });
        })
        .catch((err) => {
          showToast(
            err?.response?.data?.message ||
              err?.response?.error ||
              'Something Went Wrong.',
            ToastThemes.error
          );
          throw err?.response?.data?.message;
        });
    }
  }, [urlToken, ssoResponse]);

  useEffect(() => {
    let timer;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent, resendTimer]);

  useEffect(() => {
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      id: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  const handleCountryChange = (e) => {
    const selectedOption = e.target.value;
    const countryCode = selectedOption.id;

    setFormData({
      ...formData,
      country: selectedOption,
      state: '',
      city: '',
    });

    const stateList = State.getStatesOfCountry(countryCode).map((state) => ({
      name: state.name,
      id: state.isoCode,
    }));
    setStates(stateList);
    setCities([]);
  };

  // Fetch cities when a state is selected
  const handleStateChange = (e) => {
    const selectedOption = e.target.value;
    const stateCode = selectedOption.id;

    setFormData({
      ...formData,
      state: selectedOption,
      city: '',
    });

    const cityList = City.getCitiesOfState(formData.country.id, stateCode).map(
      (city) => ({
        name: city.name,
        id: city.name,
      })
    );
    setCities(cityList);
  };

  const handleCityChange = (e) => {
    const selectedOption = e.target.value;
    setFormData({
      ...formData,
      city: selectedOption,
    });
  };

  const handleProductChange = (e) => {
    const selectedOption = e.target.value;
    setFormData({
      ...formData,
      product: selectedOption,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, adminMobileNumber: value });
  };

  const handleOtpChange = (index, value) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken && !urlToken) {
      showToast('Please complete the reCAPTCHA.', ToastThemes.error);
      return;
    }

    setLoading(LoadingStatus.Loading);
    const { country, city, state, product, ...rest } = formData;
    const requestPayload = {
      ...rest,
      country: country.name,
      state: state.name,
      city: city.name,
      product: product.id ? product.id : product,
    };

    // Only add recaptchaToken if urlToken is not present
    if (!urlToken) {
      requestPayload.recaptchaToken = recaptchaToken;
    }
    await new CommonSevice()
      .sendOTP(requestPayload)
      .then((res) => {
        setTenantProfileId(res);
        setLoading(LoadingStatus.Success);
        if (!urlToken) {
          showToast(
            'Success! An OTP has been sent to your email. Please check your inbox',
            ToastThemes.success
          );
          setOtpSent(true);
          setResendTimer(60);
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        } else {
          setFormData({
            tenantName: '',
            tenantSubdomain: '',
            adminName: '',
            email: '',
            adminMobileNumber: '',
            country: '',
            city: '',
            state: '',
            product: '',
          });
          setOtpSent(false);
          navigate('/thank-you');
        }
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message || 'Something Went Wrong! Try Again',
          ToastThemes.error
        );
        setRecaptchaToken(null);
        window.grecaptcha?.reset();
        throw err?.response?.data?.message;
      });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(LoadingStatus.Loading);
    const otpValue = otp.join('');
    await new CommonSevice()
      .verifyOTP({
        email: formData.email,
        otp: otpValue,
        tenantProfileId: tenantProfileId,
      })
      .then(() => {
        showToast('OTP Verified!', ToastThemes.success);
        setOtpVerified(true);
        setResendTimer(0);
        setFormData({
          tenantName: '',
          tenantSubdomain: '',
          adminName: '',
          email: '',
          adminMobileNumber: '',
          country: '',
          city: '',
          state: '',
          product: '',
        });
        setLoading(LoadingStatus.Success);
        setRecaptchaToken(null);
        setOtpSent(false);
        navigate('/thank-you');
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message ||
            err?.response?.error ||
            'Invalid OTP.',
          ToastThemes.error
        );
        throw err?.response?.data?.message;
      });
  };

  const handleResendOtp = async () => {
    setLoading(LoadingStatus.Loading);
    await new CommonSevice()
      .sendOTP({ email: formData.email, existingTenantId: tenantProfileId })
      .then(() => {
        showToast(
          'Success! An OTP has been sent to your email. Please check your inbox',
          ToastThemes.success
        );
        setResendTimer(60);
        setLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message || 'Something Went Wrong! Try Again',
          ToastThemes.error
        );
        throw err?.response?.data?.message;
      });
  };
  if (loadin && urlToken) {
    return (
      <div>
        <h1>Verifying your identity...</h1>
      </div>
    );
  }

  if (!isVerified && urlToken) {
    return (
      <div>
        <h1>Redirecting to login...</h1>
      </div>
    );
  }
  return (
    <>
      <CircularLoader show={LoadingStatus.Loading === loading} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f5f5f5"
        overflowY="auto"
        padding="20px"
      >
        <Paper
          elevation={3}
          style={{ padding: '30px', maxWidth: '600px', width: '100%' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom="20px"
          >
            <img
              src={Logo} // Replace with your logo URL
              alt="Logo"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
            <Typography
              variant="h5"
              align="center"
              style={{ marginTop: '10px' }}
            >
              Subscribe Now
            </Typography>
          </Box>
          <form onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              label="Organization Name(Legal Entity Name)"
              name="tenantName"
              value={formData.tenantName}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Preferred Domain Name"
              name="tenantSubdomain"
              placeholder="abc.vexecution.com, abc is Domain Name"
              value={formData.tenantSubdomain}
              onChange={(e) => {
                const value = e.target.value; // Ensure all characters are lowercase
                const regex = /^[a-z0-9-]{0,8}$/; // Limit to 8 characters
                if (regex.test(value)) {
                  handleInputChange(e);
                }
              }}
              margin="normal"
              required
              helperText="Maximum 8 characters allowed, lowercase only."
            />
            <TextField
              fullWidth
              label="Admin Full Name"
              name="adminName"
              value={formData.adminName}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            {capturedData.email ? (
              <FieldView labelText={'Email'} labelValue={capturedData.email} />
            ) : (
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                margin="normal"
                required
              />
            )}
            <PhoneInput
              country={'sg'}
              value={formData.adminMobileNumber}
              onChange={handlePhoneChange}
              inputStyle={{
                height: '55px',
                fontWeight: 'none',
                fontSize: '18px',
                width: '100%',
                color: 'rgb(0 0 0 / 87% )',
                font: 'inherit',
              }}
              buttonStyle={{
                height: '55px',
              }}
              containerStyle={{
                width: '100%',
                marginTop: '8px',
              }}
              required
            />
            <FormControl fullWidth margin="normal" required>
              <ApiSelectTag
                defaultValue={'Product Suite'}
                fullWidth
                required={true}
                dropDownList={products}
                name="product"
                value={formData.product}
                onchange={handleProductChange}
                isReadOnly={capturedData.product ? true : false}
                labelText={capturedData.product ? 'Product Suite' : ''}
              />
            </FormControl>
            <FormControl fullWidth margin="normal" required>
              <ApiSelectTag
                defaultValue={'Country'}
                fullWidth
                required={true}
                dropDownList={countries}
                name="country"
                value={formData.country}
                onchange={handleCountryChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <ApiSelectTag
                defaultValue={'State'}
                fullWidth
                dropDownList={states}
                name="state"
                value={formData.state}
                onchange={handleStateChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <ApiSelectTag
                defaultValue={'City'}
                fullWidth
                dropDownList={cities}
                name="city"
                value={formData.city}
                onchange={handleCityChange}
              />
            </FormControl>
            {!otpSent && !urlToken && (
              <Box display="flex" justifyContent="center" marginTop="20px">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                style={{ marginTop: '20px' }}
              >
                Submit
              </Button>
            </Box>
          </form>
          {!otpVerified && otpSent && !urlToken && (
            <form onSubmit={handleOtpSubmit}>
              <Typography
                variant="h5"
                align="center"
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                Enter OTP
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom="20px"
                position="relative"
              >
                <Box display="flex" justifyContent="center" gap="10px">
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      id={`otp-${index}`}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                      }}
                      style={{ width: '50px' }}
                    />
                  ))}
                </Box>
                <Typography
                  variant="body2"
                  style={{
                    position: 'absolute',
                    bottom: '-30px',
                    right: '125px',
                    cursor: resendTimer > 0 ? 'not-allowed' : 'pointer',
                    textDecoration: resendTimer > 0 ? 'none' : 'underline',
                    color: resendTimer > 0 ? 'gray' : 'blue',
                  }}
                  onClick={resendTimer === 0 ? handleResendOtp : null}
                  onMouseOver={(e) => {
                    if (resendTimer === 0) e.target.style.color = 'darkblue';
                  }}
                  onMouseOut={(e) => {
                    if (resendTimer === 0) e.target.style.color = 'blue';
                  }}
                >
                  Resend OTP {resendTimer > 0 ? `(${resendTimer}s)` : ''}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '15px' }}
                >
                  Verify OTP
                </Button>
              </Box>
            </form>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default Subscribe;
