import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';
import { LoadingStatus, ToastThemes } from '../../../../../constants/common';
import AddCategories from '../categories-form/AddCategories';
import { claimCategories } from '../../constants/onboarding.constants';
import { claimCategoriesDashboardHeaders } from '../utils/claimCategories.utils';
import { NewClaimsService } from '../../../../../services/newClaims';
import { showToast } from '../../../../../utils/common.util';

const AllCategories = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(-1);

  const { currentUserRoleId } = useSelector((state) => state.main);
  const { permissions } = useSelector((state) => state.onBoarding);

  useEffect(async () => {
    if (currentUserRoleId && !showForm) {
      setLoading(LoadingStatus.Loading);
      await new NewClaimsService()
        .getListAllCategories(currentUserRoleId)
        .then((res) => {
          setData(res);
          setLoading(LoadingStatus.Success);
        })
        .catch((err) => {
          showToast(err?.response?.data?.message, ToastThemes.error);
          setLoading(LoadingStatus.Failure);
          throw err?.response?.data?.message;
        });
    }
  }, [currentUserRoleId, showForm]);

  useEffect(() => {
    setCheckedItems([]);
  }, [data]);

  const onClickAddIcon = () => {
    setShowForm(true);
    setViewMode(false);
    setCategoryId('');
  };

  const onDetailsClick = (id) => {
    setShowForm(true);
    setViewMode(true);
    setCategoryId(id);
  };

  const rowData = data?.map((items) => {
    const { id, name } = items;
    return {
      name,
      action: !permissions?.[claimCategories]?.[permissionsObj.allowEdit] ? (
        { hide: true }
      ) : (
        <IconButton
          onClick={() => onDetailsClick(id)}
          disabled={!permissions?.[claimCategories]?.[permissionsObj.allowEdit]}
        >
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: { id },
      },
    };
  });
  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      {showForm ? (
        <AddCategories
          viewMode={viewMode}
          setViewMode={setViewMode}
          categoryId={categoryId}
          setShowForm={setShowForm}
          setCategoryId={setCategoryId}
        />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <FormHeader title="Claim Categories" />
          </Box>
          <ListView
            headCells={claimCategoriesDashboardHeaders}
            rowsData={rowData}
            showSerialNo={true}
            showCheckbox={true}
            setCheckedItems={setCheckedItems}
            checkedItems={checkedItems}
            showAddIcon={
              permissions?.[claimCategories]?.[permissionsObj.allowAdd]
            }
            onAddClick={() => onClickAddIcon()}
          />
        </Box>
      )}
    </>
  );
};

export default AllCategories;
