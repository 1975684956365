import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';

import './Navbar.css';
import { ReactComponent as MenuIcon } from '../../assets/menu-icon.svg';
import Toggle from '../components/ui-elements/Toggle';
import Logo from '../../assets/VE_LOGO1.png';
import { decodeJWT, sortRolesArray } from '../../utils/common.util';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../services/api';
import { fetchLogo } from '../../redux/onboarding/action';
import { Box, Button } from '@mui/material';
import { FeedbackOutlined } from '@mui/icons-material';
import FeedbackModal from '../../components/feedback/Feedback';

const Navbar = (props) => {
  // const navigate = useNavigate();

  const decodeToken = decodeJWT(localStorage.getItem('token'));
  const roles = decodeToken.roles;
  const sortedRoles = sortRolesArray(roles);
  const auth = useContext(AuthContext);
  const [age, setAge] = React.useState('');
  const [userDataNew, setUserData] = useState([]);
  // const [allRole, setAllRoles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fromLogout, setFromLogout] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const { userInfo, currentUserRoleId } = useSelector((state) => state && state.main);
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!logoImage) {
      if(currentUserRoleId) {
      dispatch(fetchLogo());}
    }
  }, [currentUserRoleId]);

  const fullName = userInfo?.displayName ? userInfo?.displayName : userInfo?.firstName+' '+(userInfo?.lastName?userInfo?.lastName:'');

  const authlogoutSubmitHandler = async () => {
    // const userDetailString = localStorage.getItem('userData');
    // const userDetails = userDetailString ? JSON.parse(userDetailString): {};
    // if(userDetails?.userDetails?.client?.id === 1){
    //   auth.logout();
    //   window.location.replace('https://easy.ntuc.org.sg');
    //   return;
    // }
    auth.logout();
    window.location.reload();
  };

  const openNav = () => {
    //   document.getElementById('sidenav').style.width = '250px';
    //   document.getElementById('sidenav').style.marginLeft = '0';
    //   document.getElementById('main-content-wrap').style.marginLeft = '250px';
    props.setIsSidebarOpen(!props.isSidebarOpen);
  };

  const setAsProfile = () => {
    // localStorage.setItem('asProfile', data);
  };

  useEffect(() => {
    if (props.isSidebarOpen) {
      document.getElementById('sidenav').style.width = '250px';
      document.getElementById('sidenav').style.marginLeft = '0';
      document.getElementById('main-content-wrap').style.marginLeft = '250px';
    } else {
      document.getElementById('sidenav').style.width = '0';
      document.getElementById('sidenav').style.marginLeft = '-250px';
      document.getElementById('main-content-wrap').style.marginLeft = '0';
    }
  }, [props.isSidebarOpen]);

  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   setFullName(
  //     userData.userDetails.firstName + ' ' + userData.userDetails.lastName
  //   );
  //   setUserData(userData.userDetails.designation);

  //   // let allAvailableRoles = userData.userDetails.designation;

  //   // let newRoleData = allAvailableRoles.map((role) => {
  //   //   let updatedRole = role && role.toLowerCase();
  //   //   return {
  //   //     role: role,
  //   //     path: role == 'Employee' ? '/dashboard' : `/dashboard/${updatedRole}`,
  //   //     background: role == 'Employee' ? '#00ac94' : '#eb7e1f',
  //   //   };
  //   // });

  //   // if (newRoleData) {
  //   //   setAllRoles(newRoleData);
  //   // }
  // }, []);
const handleLogoutClick = () => {
setShowModal(true);
setFromLogout(true);
};
  return (
    <AppBar
      position="sticky"
      style={{ background: '#fff', margin: '0' }}
      className="appbar-wrap"
      elevation={1}
    >
      <Toolbar className="nav-main">
        <div className="nav-main-left-wrap">
          <IconButton onClick={openNav} className="menu-icon">
            <MenuIcon />
          </IconButton>
            <div className="logo-wrap">
              <img src={logoImage?.companyLogo?.url ||  null} />
            </div>

          {
            sortedRoles.map((role) => {
              const {id, role_name} = role;
              return (
                <Toggle
                  key={id}
                  name={role_name}
                  background={id === 1 ? '#00ac94' : '#eb7e1f'}
                  roleId={id}
                  // onClick={setAsProfile(role_name)}
                  to={'/home'}
                />
              );
            })}

          {/* {
          userDataNew.includes('Employee') && (
              <Toggle
              name='Employee'
              background='#00ac94'
              onClick={setAsProfile('Employee')}
              to='/dashboard'
            />
            )
          }

          {
            userDataNew.includes('Manager') && (
              <Toggle
              name='Manager'
              background='#eb7e1f'
              to='/dashboard/manager'
              onClick={setAsProfile('Manager')}
            />
            )
          } */}
        </div>

        <div>
          <FeedbackModal showModal={showModal} setShowModal={setShowModal} fromLogout={fromLogout}/>
          <Button variant="outlined" startIcon={<FeedbackOutlined />} style={{marginRight: '10px'}} onClick={()=>setShowModal(true)}>FEEDBACK</Button>
          <FormControl sx={{ width: 180 }}>
            <Select
              style={{ marginRight: '10px', marginBottom: '-5px' }}
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className="test"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem
                className="current-user-wrap"
                value=""
                style={{ padding: '1em' }}
              >
                <>{fullName}</>
              </MenuItem>
              <MenuItem value={30} onClick={handleLogoutClick}>
                Logout
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
