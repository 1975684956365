import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { AuthContext } from '../shared/context/auth-context';
import Dashboard from './dashboard/Dashboard';

import { useDispatch, useSelector } from 'react-redux';
import Success from './success/Success';
import Login from './login/Login';
import Layout from '../shared/layout/Layout';
import LandingPage from './landing-page/LandingPage';
import OvertimeClaim from './forms/overtime-claim/OvertimeClaim';
import CommissionClaim from './forms/commission-claim/CommissionClaim';
import FlexiClaim from './forms/flexi-claim/FlexiClaim';
import OvertimeClaimView from './forms/overtime-claim/OvertimeClaimsView';
import StaffTraining from './forms/staff-training/StaffTraining';
import UnergizerClaims from './forms/unergizer-claims/UnergizerClaims';
import UnionGathering from './forms/union-gathering/UnionGathering';
import UnionGatheringView from './forms/union-gathering/UnionGatheringView';
import TransportClaim from './forms/transport-claim/TransportClaim';
import OvertimeEdit from './forms/overtime-claim/OvertimeEdit';
import Marriage from './forms/marriage/Marriage';
import MarriageView from './forms/marriage/MarriageView';
import MarriageEdit from './forms/marriage/MarriageEdit';
import ChildBirth from './forms/child-birth/ChildBirth';
import ChildBirthView from './forms/child-birth/ChildBirthView';
import ChildBirthEdit from './forms/child-birth/ChildBirthEdit';
import Retirement from './forms/retirement/Retirement';
import RetirmentView from './forms/retirement/RetirmentView';
import ExpensesForm from './forms/expenses/ExpensesForm';
import ExpensesView from './forms/expenses/ExpensesView';
import FuneralAssistance from './forms/funeral-asssitance/FuneralAssistance';
import TransportClaimView from './forms/transport-claim/TransportView';
import TransportClaimEdit from './forms/transport-claim/TransportClaimEdit';
import FlexiClaimView from './forms/flexi-claim/FlexiClaimView';
import FlexiClaimEdit from './forms/flexi-claim/FlexiClaimEdit';
import ExpensesEdit from './forms/expenses/ExpensesEdit';
import CommissionView from './forms/commission-claim/CommissionView';
import CommissionEdit from './forms/commission-claim/CommissionEdit';
import FuneralAssistanceView from './forms/funeral-asssitance/FuneralAssistanceView';
import FuneralAssistanceEdit from './forms/funeral-asssitance/FuneralAssistanceEdit';
import StaffTrainingView from './forms/staff-training/StaffTrainingView';
import StaffTrainingEdit from './forms/staff-training/StaffTrainingEdit';
import UnergizerClaimsView from './forms/unergizer-claims/UnergizerClaimsView';
import UnergizerClaimsEdit from './forms/unergizer-claims/UnergizerClaimsEdit';
import UnionGatheringEdit from './forms/union-gathering/UnionGatheringEdit';
import StudyLoan from './forms/study-loan/StudyLoan';
import ComputerLoan from './forms/computer-loan/ComputerLoan';
import StudyLoanView from './forms/study-loan/StudyLoanView';
import ComputerLoanView from './forms/computer-loan/ComputerLoanView';
import RetirementEdit from './forms/retirement/RetirmentEdit';
import ComputerLoanEdit from './forms/computer-loan/ComputerLoanEdit';
import StudyLoanEdit from './forms/study-loan/StudyLoanEdit';
import AuditTrail from './audit-trail/AuditTrail';
import Okta from './okta/Okta';
import MarketPlace from './marketPlace/MarketPlace';
import { PROFILE } from '../constants/constants';
import MarketPlaceAuth from './marketPlace/MarketPlaceAuth';
import OnBoarding from './OnBoarding/OnBoarding';
import { Modal, ThemeProvider } from '@mui/material';
import { theme } from '../foundation/theme';
import {
  fetchAppLevelPermissions,
  setCurrentUserRoleId,
  setUserInfo,
} from '../redux/main/action';
import UserProfile from './OnBoarding/UserProfile/UserProfile';
import Claims from './claims/Claims';
import ClaimView from './claim-view/claim-view';
import DynamicEditClaim from './dynamic-edit-claim/dynamic-edit-claim';
import AddUser from './OnBoarding/UserProfile/AddUser';
import AllUsers from './OnBoarding/UserProfile/AllUsers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  APIRequestStatus,
  LoadingStatus,
  toastTimer,
} from '../constants/common';
import Departments from './OnBoarding/Organization/Departments';
import Locations from './OnBoarding/Organization/Locations';
import CompanyInfo from './OnBoarding/Organization/CompanyInfo';
import {
  companyInfo,
  departments,
  designations,
  locations,
  onboarding,
  payStructure,
  rolesAndPermission,
  approvalWorkflow,
  currencyConversion,
  externalIntegration,
  claimCategories,
} from './OnBoarding/Organization/constants/onboarding.constants';
import RolesPermissions from './OnBoarding/Organization/RolesAndPermissions/RolesPermissions';
import ModalDialog from '../atoms/Modal/ModalDialog';
import ForgotPassword from './forgot-password/ForgotPassword';
import ResetPassword from './reset-password/ResetPassword';
import Designations from './OnBoarding/Organization/Designations';
import Performance from './Performance/Performance';
import Recruiting from './Recruiting/Recruiting';
import CircularLoader from '../atoms/CircularLoader/circular-loader';
import ManageGoals from './Performance/Goals/ManageGoals';
import ManagePerformanceList from './Performance/ManageList';
import ManageAppraisal from './Performance/Appraisal/ManageAppraisal';
import AppraisalForm from './Performance/Appraisal/AppraisalForm/AppraisalForm';
import GoalsOutlet from './Performance/GoalsOutlet/GoalsOutlet';
import AppraisalOutlet from './Performance/AppraisalOutlet/AppraisalOutlet';
import ManagerRecrutingList from './Recruiting/ManagerRecrutingList';
import CandidateDetailsOutlet from './Recruiting/Outlet/CandidateDetailsOutlet/CandidateDetailsOutlet';
import JobRequisitionOutlet from './Recruiting/Outlet/JobRecquisitionOutlet/JobRequisitionOutlet';
import ManageCandidate from './Recruiting/Outlet/CandidateDetailsOutlet/CandidateDetails/ManageCandidate/ManageCandidate';
import ManageJob from './Recruiting/Outlet/JobRecquisitionOutlet/JobRequisition/JobDetails/ManageJob';
import OfferApprovalOutlet from './Recruiting/Outlet/OfferApprovalOutlet/OfferApprovalOutlet';
import AllJobs from './Recruiting/Outlet/JobRecquisitionOutlet/Dashboard/AllJobs';
import AllStructureAppwork from './OnBoarding/Organization/ApprovalFlow/ApprovalFlowDashboard/AllStructureAppWork';
import AllCandidates from './Recruiting/Outlet/CandidateDetailsOutlet/Dashboard/AllCandidates';
import AllOffers from './Recruiting/Outlet/OfferApprovalOutlet/Dashboard/AllOffers';
import Resign from './separation/resign';
import TimeManagement from './TimeManagement/TimeManagement';
import AllStructure from './OnBoarding/Organization/PayStructure/PayStructureDashboard/AllStructure';
import Payroll from './Payroll/Payroll';
import PayrollOutlet from './Payroll/PayrollOutlet/PayrollOutlet';
import ManagePayroll from './Payroll/PayrollOutlet/PayrollForm/ManagePayroll/ManagePayroll';
import FormBuilder from './form-builder/form-builder.compoent';
import ManageFormTab from './form-builder/manage-tab/manage-form-tab.component';
import ManageFormDashboard from './form-builder/dashboard/manage-dashboard.component';
import ManageClaimFormTab from './forms/claim-form-render/manage-claim-form-tab.component';
import Reports from './reports/reports.component';
import NewClaims from './new-claims/new-claims.component';
import NewClaimsAllDashBoard from './new-claims/new-claims-all-dashboard/new-claims-all-dashboard.component';
import ManageTab from './new-claims/new-claims-all-dashboard/manage-tab.component';
import NewClaimsOutlet from './new-claims/outlet/new-claims-oulet';
import CompBen from './comp-ben/comp-ben.component';
import CompBenOutlet from './comp-ben/outlet/com-ben-outlet';
import ManageCompBenTab from './comp-ben/comp-ben-dashboard/manage-tab.component';
import ManageCompBenFormTabComponent from './comp-ben/manage-comp-ben-form-tab/manage-comp-ben-form-tab.component';
import MyJourney from './OnBoarding/UserProfile/MyJourney';
import AllCurrency from './OnBoarding/Organization/currency-conversion/currency-conversion-dashboard/AllCurrency';
import AllIntegration from './OnBoarding/Organization/external-integration/IntegrationDashboard/AllIntegration';
import PowerBIDashboardEmbed from './reports/dashboard-powerbi';
import ManageUserOnboardingBuilderForm from './form-builder/user-onboarding-build/manage-user-onboarding-builder-form/manage-user-onboarding-builder-form';
import Subscribe from './subscribe/Subscribe';
import AllCategories from './OnBoarding/Organization/claim-categories/claim-categories-dashboard/AllCategories';
import ThankYouPage from './subscribe/ThankYou';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(localStorage.getItem('asProfile'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const [sessionExpired] = useState(localStorage.getItem('sessionExpired') === 'true' ? true : false);

  const sessionExpired =
    localStorage.getItem('sessionExpired') === 'true' ? true : false;

  const closeNavbar = () => {
    let sidenavEl = document.getElementById('sidenav');

    if (sidenavEl) {
      document.getElementById('sidenav').style.marginLeft = '-250px';
    }

    document.getElementById('main-content-wrap').style.marginLeft = '0';
  };

  const login = useCallback(() => {
    setIsLoggedIn(true);
    const asProfile = localStorage.getItem('asProfile');
    setUser(asProfile);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    const asProfile = localStorage.getItem('asProfile');
    localStorage.clear();
    setUser(asProfile);
  }, []);

  const logoutAndRedirectToLogin = () => {
    logout();
    window.location.replace('/login');
  };

  const isActive = localStorage.getItem('isLoggedIn');
  const {
    appPermissions,
    requestProcessing: {
      [APIRequestStatus.FETCH_APP_PERMISSIONS]: getAppPermissionLoading,
    },
  } = useSelector((state) => state && state.main);
  const { currentUserRoleId, userInfo } = useSelector(
    (state) => state && state.main
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isActive) {
      const roleId = JSON.parse(localStorage.getItem('role'))?.roleId;
      const loggedInUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (!currentUserRoleId) {
        dispatch(setCurrentUserRoleId(roleId));
      }
      if (!userInfo) {
        dispatch(setUserInfo(loggedInUserInfo));
      }
      if (!appPermissions) {
        dispatch(fetchAppLevelPermissions(roleId));
      }
    }
  }, [isActive]);

  let routes;
  if (user === 'Employee') {
    routes = (
      <Routes>
        <Route exact path="/home" element={<LandingPage />} />
        <Route path="/claims/create/:claimType" element={<TransportClaim />} />
        <Route path="/claims/edit/:claimId" element={<DynamicEditClaim />} />
        <Route path="/claims/view/:claimId" element={<ClaimView />} />
        <Route path="/claims/create/:claimType" element={<TransportClaim />} />
        <Route path="/claims/view/:claimId" element={<ClaimView />} />
        <Route exact path="/home" element={<LandingPage />} />
        <Route exact path="/claims" element={<Claims />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/onboarding" element={<OnBoarding />} />
        {/* <Route exact path='/dashboard/manager' element={<Dashboard />} /> */}
        <Route exact path="/dashboard/:id" element={<Dashboard />} />
        <Route exact path="/dashboard/admin" element={<Dashboard />} />

        <Route path="/onboarding" element={<OnBoarding />}>
          <Route
            index
            element={<Navigate to={`/${onboarding}/${companyInfo}`} replace />}
          />
          <Route path={companyInfo} element={<CompanyInfo />} />
          <Route path={rolesAndPermission} element={<RolesPermissions />} />
          <Route path={departments} element={<Departments />} />
          <Route path={locations} element={<Locations />} />
          <Route path={designations} element={<Designations />} />
          <Route path={payStructure} element={<AllStructure />} />
          <Route path={approvalWorkflow} element={<AllStructureAppwork />} />
          <Route path={currencyConversion} element={<AllCurrency />} />
          <Route path={claimCategories} element={<AllCategories />} />
          <Route path={externalIntegration} element={<AllIntegration />} />
        </Route>

        <Route path="/user-onboarding" element={<UserProfile />}>
          <Route index element={<AllUsers />} />
          <Route path=":id" element={<AddUser />} />
        </Route>

        <Route path="/my-profile" element={<UserProfile />}>
          <Route
            index
            element={<Navigate to={`/my-profile/${userInfo?.id}`} replace />}
          />
          <Route path=":id" element={<AddUser />} />
        </Route>
        <Route path="/separation" element={<UserProfile />}>
          <Route
            index
            element={<Navigate to={`/separation/${userInfo?.id}`} replace />}
          />
          <Route path=":id" element={<Resign />} />
        </Route>

        <Route exact path="/recruiting" element={<Recruiting />}>
          <Route
            index
            element={<Navigate to="/recruiting/job-requisition" replace />}
          />
          <Route path="job-requisition" element={<JobRequisitionOutlet />}>
            <Route index element={<AllJobs />} />
            <Route path=":id" element={<ManageJob />} />
          </Route>
          <Route path="candidate-details" element={<CandidateDetailsOutlet />}>
            <Route index element={<AllCandidates />} />
            <Route path=":id" element={<ManageCandidate />} />
          </Route>
          <Route path="offer-approval" element={<OfferApprovalOutlet />}>
            <Route index element={<AllOffers />} />
            {/* <Route path=':id' element={< />} /> */}
          </Route>
        </Route>

        <Route path="/time-management" element={<TimeManagement />} />

        <Route exact path="/payroll" element={<Payroll />}>
          <Route index element={<Navigate to="/payroll/:id" replace />} />
          <Route path=":id" element={<CompBenOutlet />}>
            <Route index element={<PayrollOutlet />} />
            <Route path=":id" element={<ManagePayroll />} />
          </Route>
        </Route>

        {/* <Route exact path="/builder" element={<FormBuilder />}>
          <Route index element={<ManageFormDashboard />} />
          <Route path=":id" element={<ManageFormTab />} />
        </Route> */}

        <Route exact path="/builder" element={<FormBuilder />}>
          <Route index element={<Navigate to="/builder/claims" replace />} />
          <Route path="claims" element={<GoalsOutlet />}>
            <Route index element={<ManageFormDashboard currentTab={0} />} />
            <Route path=":id" element={<ManageFormTab />} />
          </Route>
          <Route path="user-onboarding" element={<GoalsOutlet />}>
            <Route index element={<ManageFormDashboard currentTab={1} />} />
            <Route path=":id" element={<ManageUserOnboardingBuilderForm />} />
          </Route>
        </Route>

        <Route exact path="/dashboard/:id" element={<Dashboard />} />
        <Route exact path="/dashboard/admin" element={<Dashboard />} />
        <Route exact path="/claims" element={<Claims />} />

        {/* <Route exact path='/custom-claim' element={<Claims />}> */}
        {/* </Route> */}

        <Route exact path="/new-claims" element={<NewClaims />}>
          <Route index element={<Navigate to="/new-claims/:id" replace />} />
          <Route path=":id" element={<NewClaimsOutlet />}>
            <Route index element={<ManageTab />} />
            <Route path=":id" element={<ManageClaimFormTab />} />
          </Route>
        </Route>

        <Route exact path="/comp-ben" element={<CompBen />}>
          <Route index element={<Navigate to="/comp-ben/:id" replace />} />
          <Route path=":id" element={<CompBenOutlet />}>
            <Route index element={<ManageCompBenTab />} />
            <Route path=":id" element={<ManageCompBenFormTabComponent />} />
          </Route>
        </Route>

        <Route
          path="/custom-claim/:ctId/:id"
          element={<ManageClaimFormTab />}
        />

        <Route exact path="/success" element={<Success />} />
        <Route path="/my-journey/:id" element={<MyJourney />} />

        <Route exact path="/performance" element={<Performance />}>
          <Route index element={<Navigate to="/performance/goals" replace />} />
          <Route path="goals" element={<GoalsOutlet />}>
            <Route index element={<ManagePerformanceList currentTab={0} />} />
            <Route path=":id" element={<ManageGoals />} />
          </Route>
          <Route path="appraisal" element={<AppraisalOutlet />}>
            <Route index element={<ManagePerformanceList currentTab={1} />} />
            <Route path=":id" element={<ManageAppraisal />} />
          </Route>
        </Route>
        <Route
          exact
          path="/employee-portal/transport-claim"
          element={<TransportClaim />}
        />

        <Route
          exact
          path="/employee-portal/transport-claim/view"
          element={<TransportClaimView />}
        />

        <Route
          exact
          path="/employee-portal/transport-claim/edit"
          element={<TransportClaimEdit />}
        />

        <Route
          exact
          path="/employee-portal/overtime-claim"
          element={<OvertimeClaim />}
        />
        <Route
          exact
          path="/employee-portal/overtime-claim/view"
          element={<OvertimeClaimView />}
        />
        <Route
          exact
          path="/employee-portal/overtime-claim/edit"
          element={<OvertimeEdit />}
        />
        <Route
          exact
          path="/employee-portal/commission-claim"
          element={<CommissionClaim />}
        />
        <Route
          exact
          path="/employee-portal/commission-claim/view"
          element={<CommissionView />}
        />
        <Route
          exact
          path="/employee-portal/commission-claim/edit"
          element={<CommissionEdit />}
        />
        <Route
          exact
          path="/employee-portal/flexible-benefit"
          element={<FlexiClaim />}
        />
        <Route
          exact
          path="/employee-portal/flexible-benefit/view"
          element={<FlexiClaimView />}
        />
        <Route
          exact
          path="/employee-portal/flexible-benefit/edit"
          element={<FlexiClaimEdit />}
        />
        <Route
          exact
          path="/employee-portal/expenses/:id"
          element={<ExpensesForm />}
        />
        <Route
          exact
          path="/employee-portal/expenses/:linkId/view"
          element={<ExpensesView />}
        />
        <Route
          exact
          path="/employee-portal/expenses/:pathId/edit"
          element={<ExpensesEdit />}
        />
        <Route
          exact
          path="/employee-portal/staff-training"
          element={<StaffTraining />}
        />
        <Route
          exact
          path="/employee-portal/staff-training/view"
          element={<StaffTrainingView />}
        />
        <Route
          exact
          path="/employee-portal/staff-training/edit"
          element={<StaffTrainingEdit />}
        />
        <Route
          exact
          path="/employee-portal/unergizer-claim"
          element={<UnergizerClaims />}
        />
        <Route
          exact
          path="/employee-portal/unergizer-claim/view"
          element={<UnergizerClaimsView />}
        />
        <Route
          exact
          path="/employee-portal/unergizer-claim/edit"
          element={<UnergizerClaimsEdit />}
        />
        <Route
          exact
          path="/employee-portal/union-gathering"
          element={<UnionGathering />}
        />
        <Route
          exact
          path="/employee-portal/union-gathering/view"
          element={<UnionGatheringView />}
        />
        <Route
          exact
          path="/employee-portal/union-gathering/edit"
          element={<UnionGatheringEdit />}
        />
        <Route exact path="/employee-portal/marriage" element={<Marriage />} />
        <Route
          exact
          path="/employee-portal/marriage/view"
          element={<MarriageView />}
        />
        <Route
          exact
          path="/employee-portal/marriage/edit"
          element={<MarriageEdit />}
        />
        <Route
          exact
          path="/employee-portal/child-birth"
          element={<ChildBirth />}
        />
        <Route
          exact
          path="/employee-portal/child-birth/view"
          element={<ChildBirthView />}
        />
        <Route
          exact
          path="/employee-portal/child-birth/edit"
          element={<ChildBirthEdit />}
        />
        <Route
          exact
          path="/employee-portal/retirement"
          element={<Retirement />}
        />
        <Route
          exact
          path="/employee-portal/retirement/view"
          element={<RetirmentView />}
        />
        <Route
          exact
          path="/employee-portal/retirement/edit"
          element={<RetirementEdit />}
        />
        <Route
          exact
          path="/employee-portal/funeral-assistance"
          element={<FuneralAssistance />}
        />
        <Route
          exact
          path="/employee-portal/funeral-assistance/view"
          element={<FuneralAssistanceView />}
        />
        <Route
          exact
          path="/employee-portal/funeral-assistance/edit"
          element={<FuneralAssistanceEdit />}
        />
        <Route
          exact
          path="/employee-portal/study-loan"
          element={<StudyLoan />}
        />
        <Route
          exact
          path="/employee-portal/study-loan/view"
          element={<StudyLoanView />}
        />
        <Route
          exact
          path="/employee-portal/study-loan/edit"
          element={<StudyLoanEdit />}
        />
        <Route
          exact
          path="/employee-portal/computer-loan"
          element={<ComputerLoan />}
        />
        <Route
          exact
          path="/employee-portal/computer-loan/view"
          element={<ComputerLoanView />}
        />
        <Route
          exact
          path="/employee-portal/computer-loan/edit"
          element={<ComputerLoanEdit />}
        />
        <Route
          exact
          path="/employee-portal/audit-trail"
          element={<AuditTrail />}
        />
        <Route exact path="/reports" element={<Reports />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/subscribe" element={<Subscribe />} /> 
        <Route exact path="/thank-you" element={<ThankYouPage />} /> 
        <Route exact path="/saml2" element={<Okta />} />
        <Route
          exact
          path="/marketplace"
          element={
            user === PROFILE.MARKETPLACE_USER ? (
              <MarketPlace />
            ) : (
              <MarketPlaceAuth login />
            )
          }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Layout
              isLoggedIn={user && user !== PROFILE.MARKETPLACE_USER}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            >
              <main
                id="main-content-wrap"
                onMouseEnter={() => {
                  closeNavbar();
                  setIsSidebarOpen(false);
                }}
              >
                {sessionExpired && (
                  <ModalDialog
                    showModal={sessionExpired}
                    onClickClose={logoutAndRedirectToLogin}
                    onClickConfirm={logoutAndRedirectToLogin}
                    primaryAction={'SignIn'}
                    title={'SESSION EXPIRED'}
                    description={
                      'Your session expired. please relogin to continue.'
                    }
                  />
                )}
                {routes}
                <ToastContainer autoClose={toastTimer} />
              </main>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      </React.StrictMode>
    </AuthContext.Provider>
  );
};

export default App;
