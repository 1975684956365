import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore, PersonOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ActionHeader from '../../Organization/ActionHeader/ActionHeader';
import {
  UserInfoFormFields,
  UserStatus,
} from '../../Organization/utils/userOnboarding/userOnboarding.util';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { utcToLocal } from '../../../../utils/formatter';
import StyledButton from '../../Organization/ActionHeader/ActionHeader.styled';

const MyJourneyDashboard = (props) => {
  const { empId, userName, status, userLog } = props;
  const { userInfo: loggedInUserDetails, currentUserRoleId } = useSelector(
    (state) => state.main
  );
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const navigate = useNavigate();

  const [open, setOpen] = useState({ 0: true });
  const [allOpen, setAllOpen] = useState(false);

  const toggleAccordion = (index) => {
    setOpen((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  const toggleAllAccordions = () => {
    const newOpenState = {};
    userLog?.forEach((_, index) => {
      newOpenState[index] = !allOpen;
    });
    setOpen(newOpenState);
    setAllOpen(!allOpen);
  };

  const deltas = [];

  const keysToIgnore = [
    'log_change_event_utc',
    'pk_log_id',
    'op_action',
    'log_change_event_by_name',
    'log_change_event_by',
    'id',
  ];

  userLog?.forEach((record, index) => {
    if (index === userLog.length - 1 && record.op_action === 'Created') {
      const fieldsWithValues = {};
      for (const key in record) {
        if (!keysToIgnore.includes(key) && record[key]) {
          fieldsWithValues[key] = {
            oldValue: null,
            newValue: record[key],
          };
        }
      }
      if (Object.keys(fieldsWithValues).length > 0) {
        deltas.push({
          logId: record.pk_log_id,
          changes: fieldsWithValues,
          logChangeEventUtc: record.log_change_event_utc,
          opAction: record.op_action,
          logchangeeventbyname: record.log_change_event_by_name,
          log_change_event_by: record.log_change_event_by,
        });
      }
    }
    if (index > 0) {
      const previousRecord = userLog[index - 1];
      const changes = {};
      for (const key in record) {
        if (
          !keysToIgnore.includes(key) &&
          previousRecord[key] !== record[key]
        ) {
          changes[key] = {
            oldValue: record[key],
            newValue: previousRecord[key],
          };
        }
      }
      if (Object.keys(changes).length > 0) {
        deltas.push({
          logId: record.pk_log_id,
          changes: changes,
          logChangeEventUtc: previousRecord.log_change_event_utc,
          opAction: previousRecord.op_action,
          logchangeeventbyname: previousRecord.log_change_event_by_name,
          log_change_event_by: previousRecord.log_change_event_by,
        });
      }
    }
    deltas.sort(
      (a, b) => new Date(b.logChangeEventUtc) - new Date(a.logChangeEventUtc)
    );
  });

  function findLabelByFieldRefName(fieldRefName) {
    for (const tab in UserInfoFormFields) {
      const fields = UserInfoFormFields[tab];
      for (const field of fields) {
        if (field.fieldRefName === fieldRefName) {
          return field.fieldLabel;
        }
      }
    }
    return fieldRefName === 'employeeStatus'
      ? 'Employee Status'
      : fieldRefName === 'maritalStatus'
      ? ' Marital Status'
      : fieldRefName;
  }
  return (
    <>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={userName || 'New User'}
          showPublish={false}
          showPublishModal={false}
          showSave={false}
          firstButton={(onClick) => (
            <StyledButton onClick={onClick}>
              {allOpen ? 'Collapse All' : 'Expand All'}
            </StyledButton>
          )}
          handleFirstButtonClick={toggleAllAccordions}
        />
      </Box>
      <Timeline position="alternate">
        {deltas?.map((items, index) => {
          const {
            changes,
            logChangeEventUtc,
            opAction,
            logchangeeventbyname,
            log_change_event_by,
          } = items;

          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography variant="subtitle2" fontSize={15}>
                  {Number(empId) === log_change_event_by
                    ? 'Self'
                    : logchangeeventbyname}
                </Typography>
                <Typography variant="subtitle2" fontSize={13}>
                  {utcToLocal(
                    logChangeEventUtc,
                    'YYYY-MM-DD HH:mm:ss',
                    'YYYY-MM-DD hh:mm:ss a'
                  )}
                </Typography>
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#106fbc' : '#fcc54f',
                  }}
                >
                  <PersonOutline
                    sx={{ color: index % 2 === 0 ? '#fff' : '#000' }}
                  />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Card
                  sx={{
                    width: open[index] ? '60%' : '50%',
                    marginLeft: index % 2 === 0 ? 0 : 'auto',
                    marginRight: index % 2 === 0 ? 'auto' : 0,
                    transition: 'width 0.3s ease, margin 0.3s ease',
                  }}
                >
                  <IconButton
                    onClick={() => toggleAccordion(index)}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                    disableRipple
                    disableFocusRipple
                  >
                    {index % 2 === 0 ? (
                      <>
                        <Typography variant="body1">
                          Action: {opAction}
                        </Typography>
                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                      </>
                    ) : (
                      <>
                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                        <Typography variant="body1">
                          Action: {opAction}
                        </Typography>
                      </>
                    )}
                  </IconButton>

                  <Collapse in={open[index]} timeout="auto" unmountOnExit>
                    <CardContent>
                      {Object.entries(changes).map(
                        ([key, { oldValue, newValue }]) => (
                          <>
                            <Typography fontWeight="bold">
                              {`${findLabelByFieldRefName(key)} : `}
                            </Typography>
                            <Typography>
                              {oldValue ? (
                                <>
                                  <span style={{ color: 'red' }}>
                                    {oldValue}
                                  </span>
                                  {' --> '}
                                  <span style={{ color: 'green' }}>
                                    {newValue}
                                  </span>
                                </>
                              ) : (
                                <span style={{ color: 'green' }}>
                                  {newValue}
                                </span>
                              )}
                            </Typography>
                          </>
                        )
                      )}
                    </CardContent>
                  </Collapse>
                </Card>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
      <Box mb={3} mt={1}>
        {status === UserStatus.Resign ? (
          <Button
            variant="contained"
            size="medium"
            onClick={() => navigate({ pathname: `/my-journey/${empId}` })}
          >
            View Resignation
          </Button>
        ) : (
          loggedInUserDetails?.id == empId &&
          currentUserRoleId === Number(logoImage?.empRoleId) &&
          !loggedInUserDetails?.roles?.includes(
            Number(logoImage?.tntOwnerRoleId)
          ) && (
            <Button
              variant="contained"
              size="medium"
              onClick={() => navigate('/separation/resign')}
            >
              Resign
            </Button>
          )
        )}
      </Box>
    </>
  );
};

export default MyJourneyDashboard;
