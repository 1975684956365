import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import Logo from '../../assets/tenant-logo.png';
import { useStyles } from '../login/Login';
import * as Joi  from 'joi-browser';
import { mainServices } from '../../services/main';
import veLOGO from '../../assets/VE_LOGO1.png';

export default function ForgotPassword() {
    const [values, setValues] = useState({email:''});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const classes = useStyles();
    const validationSchema = {
        email: Joi.string().email().required().label('Email')
    };
    const hostname = window.location.hostname;
    const tenant =
      hostname.split('.')[0] === 'localhost'
        ? process.env.REACT_APP_LOCALHOST_CLIENT_ID
        : hostname.split('.')[0];

    const handleSubmit = async ()=>{
        setSubmitted(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        setSuccess(false);
 
        const {error} = Joi.object(validationSchema).validate({
            email: values.email
        });
        const err = {};
        if (error){
            error.details?.forEach((item)=>{
                err[item.context.key] = item.message;
            });
        }
        setError(err);
        // call the api
            try {
                const api = new mainServices();
                const data = await api.forgotPassword({email: values.email});
                console.log(data);
                setSuccess(true);
                setSuccessMessage('Reset Link has been sent to your registered email. please follow the link');
            } catch (error) {
                console.log(error?.response?.data);
                setErrorMessage(error?.response?.data?.message || error.message);
            }
        setSubmitted(false);
    };
    const handleChange = (event)=>{
            setSuccess(false);
            setSuccessMessage(null);
            setErrorMessage(null);
            setSubmitted(false);
            const name = event.target.name;
            const value = event.target.value;
            setValues({...values,[name]: value});
            const {error: err} =  validationSchema[name].validate(value);
            console.log(err);
            if(err){
                const temp = {...error, [name]: err.message};
                setError(temp);
            }else {
                const temp = {...error};
                delete temp[name];
                setError(temp);
            }
    };
  return (
    <React.Fragment>
    <Grid container className="login-screen-wrap" style={{ padding: '5%' }}>
      <Grid item xs={12} sm={12} md={6} className="login-inputs-container">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            minHeight: '80vh',
            background: '#ffffff',
          }}
        >
          <Grid xs={11} sm={6} style={{}}>
            <Grid container>
              <h2 style={{ padding: '0.1em' }} className="client-logo">
                {/* <img src={} alt='NTUC'/> */}
                <img src={tenant === 'yts' ? Logo : veLOGO} alt="vExecution" />
              </h2>

              <Grid xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                  <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                        {errorMessage && <Typography color={'red'}>
                        {errorMessage}
                        </Typography>}
                        {success && (<Typography color={'green'}>
                        {successMessage}
                        </Typography>)}
                    </Grid>

                    <Grid item xs={12}>
                      <label htmlFor="" style={{ fontSize: '15px' }}>
                        Email
                      </label>
                      <TextField
                        type="email"
                        name="email"
                        required
                        variant="outlined"
                        size="small"
                        value={values.email}
                        fullWidth
                        onChange={handleChange}
                        classes={classes}
                        error={error.email ? true : false}
                        helperText={error.email || ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ButtonUI
                        backgroundColor="#00ac94"
                        color="#ffffff"
                        onclick={handleSubmit}
                        width="100%"
                        className="login-btn"
                        disabled={success}
                      >
                        {submitted ? <CircularProgress style={{color: 'white'}} size={'30px'} /> : 'Request Reset Password'}
                      </ButtonUI>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
  );
}
