import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { fieldsForClaimCategories } from '../utils/claimCategories.utils';
import { LoadingStatus, ToastThemes } from '../../../../../constants/common';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CategoriesFormView from './categories-form/categories-form-view';
import CategoriesFormEdit from './categories-form/categories-form-edit';
import { NewClaimsService } from '../../../../../services/newClaims';
import { showToast } from '../../../../../utils/common.util';

const AddCategories = (props) => {
  const { viewMode, setViewMode, categoryId, setShowForm, setCategoryId } =
    props;
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(-1);

  useEffect(async () => {
    if (currentUserRoleId) {
      if (categoryId) {
        setLoading(LoadingStatus.Loading);
        await new NewClaimsService()
          .getCategoryDetail(categoryId)
          .then((res) => {
            setFormData(res);
            setLoading(LoadingStatus.Success);
            setViewMode(true);
          })
          .catch((err) => {
            showToast(err?.response?.data?.message, ToastThemes.error);
            setLoading(LoadingStatus.Failure);
            throw err?.response?.data?.message;
          });
      }
    }
    return () => {
      setViewMode(false);
    };
  }, [categoryId, currentUserRoleId]);

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading && categoryId} />
      {loading === LoadingStatus.Success && viewMode ? (
        <CategoriesFormView
          formConfig={fieldsForClaimCategories}
          setViewMode={setViewMode}
          isNew={!categoryId}
          formData={formData}
          setShowForm={setShowForm}
        />
      ) : (
        <CategoriesFormEdit
          formConfig={fieldsForClaimCategories}
          setViewMode={setViewMode}
          formData={formData}
          isNew={!categoryId}
          setShowForm={setShowForm}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
        />
      )}
    </>
  );
};

export default AddCategories;
