export const FormFieldNames = {
    Roles: 'roles',
    Departments: 'department',
    Locations: 'location',
    ReportingTo: 'reportingTo',
    EmergencyMobileNumber: 'emergencyMobileNumber',
    DateOfJoining: 'dateOfJoining',
    AddedBy: 'addedBy',
    AddedOn: 'addedOn',
    FirstName: 'firstName',
    LastName: 'lastName',
    DisplayName: 'displayName',
    UpdatedAt: 'updatedAt',
    Gender: 'gender',
    BloodGroup: 'bloodGroup',
    WorkStatus: 'employeeStatus',
    SecondaryReportingTo: 'secondaryReportingTo',
    Designation: 'designation',
    OfficialEmail: 'companyEmail',
    EmployeeId: 'employeeId',
    CostCentre: 'costCentre',
    hrStatus: 'hrStatus',
    BusinessUnits: 'businessUnit',
    OrgDivisions: 'division',
    EmploymentTypes: 'empType',
    Nationality: 'nationality',
    Religion: 'religion',
    Race: 'ethnicity',
    Function: 'function',
    Category: 'category',
    ContractStartDate: 'contractStartDate',
    ContractEndDate: 'contractEndDate',
    ProbationConfirmation: 'probationConfDate',
    SubDepartments: 'subDepartments',
    ProbationMonths: 'probationPeriodMonths',
    EndDate: 'workAuthorizationEndDate',
    StartDate: 'workAuthorizationStartDate',
    WorkAuthorisation: 'workAuthorization',
    FINNumber: 'finNumber',
    NRICNumber: 'nricNumber',
    PassportNumber: 'passportNumber',
    Documents: 'documents',
    EmployeeStatus: 'employeeStatus',
    AssignTag: 'assignTag',
    Assignpaystr: 'payStructure',
    EmergencyMobileNumberRelation: 'emergencyMobileNumberRelation',
    EmploymentConvertedDate: 'employmentConvertedDate'
};

export const URLSearchParams = {
    userId: 'uid'
};

export const companyInfoConstants = {
    imageValidation: '.jpg, .jpeg, .png, .svg',
    imagePlaceholder: 'Upload in PNG, JPEG or SVG format'
};
export const saveChanges = 'Save Changes';
export const dontSave = 'Don\'t Save';
export const cancel = 'Cancel';
export const saveChangesDesc = 'You have unsaved changes. Do you want to save?';

export const stringSubstitute = '{substitute}';

export const ToastMessages = {
    success: `${stringSubstitute} Successfully!`,
    failed: `Unable to ${stringSubstitute}. Please retry`,
};

export const ToastValidationMeassge = 'Mandatory fields can\'t be empty';

export const companyInfo = 'company-info';
export const rolesAndPermission = 'roles-and-permissions';
export const locations = 'locations';
export const departments = 'departments';
export const designations = 'designations';
export const onboarding = 'onboarding';
export const nameValidationErrorMessage = 'Name Already Exist or Invalid Name';
export const userProfile = 'user-profile';
export const workDetails = 'work-details';
export const myJourney = 'seperation';
export const personalDetails = 'personal-details';
export const userInfo = 'user-info';
export const userOnboarding = 'user-onboarding';
export const addUser = 'add-user';
export const allUsers = 'all-users';
export const userId = 'userId';
export const myProfile = 'my-profile';
export const autoGenerateEmpId = 'autoGenerateEmpId';
export const documents= 'user-documents';
export const approvalWorkflow = 'approval-workflow';
export const employeeHistory= 'user_employment_history';
export const educationHistory= 'user_educational_history';
export const seperation = 'seperation';
export const allowActivate = 'allow-activate';
export const payStructure = 'salary-structure';
export const payrollAssignment = 'payroll-assignment';
export const enitilement = 'user-entitlement';
export const currencyConversion = 'currency-conversion';
export const claimCategories = 'claim-categories';
export const externalIntegration = 'external-integration';

export const UserOnboardingTabs = {
    userInfo,
    personal: personalDetails,
    workDetails,
    documents,
    employeeHistory,
    educationHistory,
    myJourney,
    payrollAssignment,
    enitilement,
};

export const WorkAuthorisation = {
    isFinMandatory: 'isFinMandatory',
    isNricMandatory: 'isNricMandatory',
    isPassportMandatory: 'isPassportMandatory',
    nameOfRequiredDocument: 'nameOfRequiredDocument',
    nameOfRequiredEndDate: 'nameOfRequiredEndDate',
    nameOfRequiredStartDate: 'nameOfRequiredStartDate'
};

export const AppLevelPermissions = {
    Performance: 'performance-app',
    Job: 'recruiting',
    TimeManagement: 'time-management',
    Payroll: 'payroll',
    FormBuilder: 'builder',
    CompAndBen: 'comp-ben-app'
};

export const EmployeeFormFields = {
    Position: 'jobPosition',
    StartDate: 'startDate',
    EndDate: 'endDate',
    Location: 'fkCountry'
};

export const EducationFormFields = {
    Qualification: 'qualification',
    StartDate: 'programStartDate',
    EndDate: 'programEndDate',
    Location: 'fkCountry'
};

export const ApprovalWorkFlowLib = {
    Name:'name',
    FormName: 'fkAwfFormId',
    ModuleName: 'moduleName',
    Library: 'fkLib',
    ApproverType:'approverType',
    formInputTaken:'formInputTaken',
    approverUserId:'approverId',
    approverRoleID:'approverRoleId',
    sendBackStep:'sendBackStep',
    userBasedApprover:'userBasedRole',
    isEmailNotificationEnabled:'isEmailNotificationEnabled',
    isApprovalConditional:'isApprovalConditional',
    fkConditionalCriteriaId:'fkConditionalCriteriaId',
    approvalConditionsBtn:'approvalConditionsBtn',
    reportingLevel:'reportingLevel',
    emailTemplate:'emailTemplate',
    isSendBackEmail:'isSendBackEmail',
    isFinalApprovalEmail:'isFinalApprovalEmail',
    isRejectionEmail:'isRejectionEmail',
    approvalEmailTemplates:'approvalEmailTemplates',
    sendBackEmailTemplate:'sendBackEmailTemplate',
    rejectionEmailTemplate:'rejectionEmailTemplate',
    finalApprovalEmailTemplate:'finalApprovalEmailTemplate',
    step0EmailTemplate:'step0EmailTemplate',
    isStep0EmailTemplate:'isStep0EmailTemplate',
    isStep0isdailyUpdateEmail:'isStep0isdailyUpdateEmail',
    isLastStepReportingManager:'isLastStepReportingManager',
    lastStepReportingManagerEmailTemplate:'lastStepReportingManagerEmailTemplate',
    isLastStepReportingManagerEmailTemplate:'isLastStepReportingManagerEmailTemplate',
    isLastStepReportingManagerisdailyUpdateEmail:'isLastStepReportingManagerisdailyUpdateEmail'
};
export const PayStrFormField = {
    Name: 'name',
    Library: 'fkLib',
    Base: 'fkSalaryTypeCode',
    Addition: 'additionComponents',
    Deducation: 'deductionComponents',
    SalaryComponent: 'salaryComponents'
};

export const NewPayStructure = 'New Structure';

export const SeparationRejectReasons = [
    'Retained ',
    'Last Working Date',
    'Handover Document ',
    'IT Compliance  ',
    'HR Compliance',
    'Finance Compliance',
    'To be Discussed',
];