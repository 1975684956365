import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Paper, Typography } from '@mui/material';
import React from 'react';
import StyledButton from './button-dropdown.styled';
import ModalDialog from '../Modal/ModalDialog';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const ButtonDropdown = (props) => {

  const { options, onOptionClick, disabled, checkedItems, showModal, setshowModal} = props;
  const { users } = useSelector((state) => state.onBoarding);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [context, setContext] = useState();
  const [id, setId] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (name, id) => {
    setshowModal(true) ; 
    setContext(name);
    setId(id);
    setAnchorEl(null);
  };
  
  return (
    <Box position='relative'>
      <StyledButton variant='contained'
        onClick={handleClick}
        endIcon={<KeyboardArrowDownOutlined />}
        disabled={disabled}
      >
        Onboard
      </StyledButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      >
        {options.filter((item) => !item.hide).map((item) => {
          return (
            <MenuItem 
            key={item.id} 
            onClick={() => handleOptionClick(item.name, item.id)}
            disabled={item.disabled}
            ><Typography variant='body3'>{item.name}</Typography></MenuItem>
            );
        })}
      </Menu>
      <ModalDialog
        title={checkedItems?.length===1?`${context} User`: `${context} ${checkedItems?.length} Users`}
        description= {` Are you sure you want to ${checkedItems?.length===1? `${context} User ?`: `${context} ${checkedItems?.length} Users ?`} `}
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={() => onOptionClick(id)}
        severity='success'
        primaryAction='Confirm'
        secondaryAction='Cancel'
      />
    </Box>
  );
};

export default ButtonDropdown;