import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ModalDialog from '../../atoms/Modal/ModalDialog';
import { Box, Typography } from '@mui/material';
import {
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
} from '../../constants/common';
import FormEdit from '../OnBoarding/common/Form/FormEdit';
import { CommonSevice } from '../../services/common';
import { getFormBody } from '../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import API from '../../services/api';
import { useSelector } from 'react-redux';
import { showToast } from '../../utils/common.util';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

const formConfig = [
  {
    fieldLabel: 'Select feedback',
    placeholderText: 'Select Option',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'optionId',
    fieldSize: 13,
  },
  {
    fieldLabel: 'Let us know what\'s on your mind ',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.MultiLineText,
    isRequired: true,
    fieldRefName: 'remarks',
    fieldSize: 13,
  },
  {
    fieldLabel: 'Supporting Document',
    placeholderText: 'Upload in PNG, JPEG or PDF format',
    fieldType: FormFieldTypes.File,
    isRequired: false,
    fieldRefName: 'attachment',
    fieldSize: 13,
  },
];

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const FeedbackModal = (props) => {
  const { showModal, setShowModal, fromLogout } = props;
  const [formInfo, setFormInfo] = useState({});
  const [option, setOption] = useState([]);
  const [loading, setLoading] = useState(-1);
  const [confirmClose, setConfirmClose] = useState(false);
  const { logoImage } = useSelector((state) => state && state.onBoarding);

  useEffect(() => {
    if (showModal === true) {
      setConfirmClose(false);
    }
  }, [showModal]);

  const handleSubmit = async () => {
    setLoading(LoadingStatus.Loading);
    try {
      const res = await new CommonSevice().postFeedBack(getFormBody(formInfo));
      showToast('Thank you for your valuable feedback!🌟', ToastThemes.success);
      setLoading(LoadingStatus.Success);
      setConfirmClose(true);
      setShowModal(false);
      setFormInfo({});
      if (fromLogout) {
        new API().logout(logoImage?.logOutUrl);
      }
    } catch (err) {
      console.error('Error during form submission:', err);
      if (err.message && err.message.includes('Invalid token')) {
        localStorage.clear();
        window.location.reload();
        if (logoImage?.logOutUrl) {     
          window.location.href = logoImage?.logOutUrl;
        }
      } else {
        const errorMessage =
          err?.response?.data?.message || 'An unexpected error occurred.';
        showToast(errorMessage, ToastThemes.error);
      }
      setLoading(LoadingStatus.Failure);
      setConfirmClose(false);
      throw err;
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case 'optionId': {
        return new CommonSevice().getFeedbackOption().then((res) => {
          setOption(res);
          return true;
        });
      }
    }
  };
  const getDropdownOptions = (name) => {
    switch (name) {
      case 'optionId': {
        return option;
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormInfo({});
    setConfirmClose(false);
    if (fromLogout) {
      new API().logout(logoImage?.logOutUrl);
    }
  };
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <ModalDialog
        severity={'success'}
        title={'Help us improve the product'}
        listComponent={
          <>
            <Typography variant="caption" color={'neutral.dark80'}>
              Required fields are marked with an asterisk
              <Box
                color="accentSecondary.main"
                sx={{ display: 'inline-block' }}
              >
                *
              </Box>
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <Typography
                variant="body2"
                color={'neutral.dark80'}
                mb={1}
                sx={{ marginRight: 2 }}
              >
                Rate your experience
                <Box
                  color="accentSecondary.main"
                  sx={{ display: 'inline-block' }}
                >
                  *
                </Box>
              </Typography>
              <StyledRating
                name="customized-icons"
                value={formInfo?.rating}
                onChange={(event, newValue) => {
                  setFormInfo((prev) => ({ ...prev, rating: newValue }));
                }}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value]?.label || ''}
                highlightSelectedOnly
                size="large"
              />
            </div>
            <br />
            <FormEdit
              list={formConfig}
              formInfo={formInfo}
              setFormData={setFormInfo}
              hideFields={false}
              padding={0}
              modal={false}
              getDropdownOptions={getDropdownOptions}
              handleDropdownClick={handleDropdownClick}
            />
            <br />
            <div className="checkboxes">
              <label>
                <input
                  type="checkbox"
                  checked={formInfo?.consent}
                  onChange={(e) =>
                    setFormInfo((prev) => ({
                      ...prev,
                      consent: e.target.checked,
                    }))
                  }
                />
                Yes, I agree to be contacted to improve the product and
                services. I acknowledge the{' '}
                <a href="https://vexecution.com/privacy-policy">
                  vExecution Privacy policy{' '}
                </a>
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={formInfo?.participation}
                  onChange={(e) =>
                    setFormInfo((prev) => ({
                      ...prev,
                      participation: e.target.checked,
                    }))
                  }
                />
                {'I\'d like to participate in product research.'}
              </label>
            </div>
          </>
        }
        primaryAction="Send Feedback"
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={handleCloseModal}
        onClickConfirm={handleSubmit}
        maxWidth={'lg'}
        fullWidth={true}
        primaryButtonDisabled={
          !formInfo?.rating || !formInfo?.optionId || !formInfo?.remarks
        }
        confirmClose={confirmClose}
      />
    </>
  );
};

export default FeedbackModal;
