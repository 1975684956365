import API from './api';

export class NewClaimsService extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/claims/' || '';
  constructor() {
    super(NewClaimsService.baseURL);
  }
  getCliamType = async (roleId) => (
    this.responsify(await this.instance.get('claim-types', {params:{roleId}}))
  );

  getAllCategories = async () => (
    this.responsify(await this.instance.get('main/categories'))
  );

  getAllValidCategories = async (roleId) => (
    this.responsify(await this.instance.get('list-create-claim-categories',{params:{roleId}}))
  );

  postUnergizer = async (body) => (
    this.responsify(await this.instance.post('save-unergizer', body))
  );

  getUnergizer = async (benefitId, claimId) => (
    this.responsify(await this.instance.get('unergizer-details', {params: {benefitId, claimId}}))
  );

  deleteUnergizer = async (unergizerId) => (
    this.responsify(await this.instance.delete('delete-unergizer', {params: {unergizerId}}))
  );

  getUserList = async () => (
    this.responsify(await this.instance.get('get-user-list'))
  );
 
  getListAllCategories = async (roleId) => (
    this.responsify(await this.instance.get('list-categories',{params:{roleId}}))
  );

  postCategories = async (formData) => (
    this.responsify(await this.instance.post('create-category', formData))
  );

  getCategoryDetail = async (id) => (
    this.responsify(await this.instance.get('get-category-details', {params: {id}}))
  );
}