import { FormFieldTypes } from '../../../../../constants/common';

export const claimCategoriesDashboardHeaders = [
  {
    id: 'name',
    label: 'Category Name',
  },
];

export const fieldsForClaimCategories = [
  {
    id: 1,
    fieldLabel: 'Category Name',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    isRequired: true,
    isHidden: false,
    fieldRefName: 'name',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
];

