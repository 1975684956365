import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { approvalFlowListHeader } from '../../utils/onboarding.util';
import {
  APIRequestStatus,
  LoadingStatus,
} from '../../../../../constants/common';
import AppWorkStr from '../ApprovalFlowForm/AddAppWork';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { InfoOutlined } from '@mui/icons-material';
import {
  getAllApprovalWorkflowList,
  resetApprovalWorkflowData,
  resetApprovalWorkflowStructure,
} from '../../../../../redux/approvalFlow/action';
import { searchQuery } from '../../../../../constants/constants';
import { useSearchParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { formatUtcDate } from '../../../../../utils/formatter';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';
import { approvalWorkflow } from '../../constants/onboarding.constants';

const AllStructureAppwork = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const {
    approvalWorkflowList,
    requestProcessing: {
      [APIRequestStatus.GET_ALL_APPROVAL_WORKFLOW_LIST]: loadingStatus,
    },
  } = useSelector((state) => state.approvalWorkflow);
  const [viewMode, setViewMode] = useState(false);
  const [strucutreId, setStructureId] = useState();
  const [showForm, setShowForm] = useState(false);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchInput = searchParams.get(searchQuery);
  const { permissions } = useSelector((state) => state.onBoarding);

  useEffect(() => {
    if (currentUserRoleId && !showForm) {
      dispatch(getAllApprovalWorkflowList(currentUserRoleId, searchInput));
    }
  }, [currentUserRoleId, showForm]);
  useEffect(() => {
    setCheckedItems([]);
  }, [approvalWorkflowList]);

  const onDetailsClick = (id) => {
    setShowForm(true);
    setViewMode(true);
    setStructureId(id);
  };
  const onClickAddIcon = () => {
    setShowForm(true);
    setStructureId('');
    dispatch(resetApprovalWorkflowData());
  };
  const rowData = approvalWorkflowList?.map((items) => {
    const { id, name, countOfSteps, isPublished, createdUtc, fkAwfFormId } =
      items;
    return {
      name: name,
      steps: countOfSteps,
      createdOn: formatUtcDate(createdUtc, 'YYYY/MM/DD'),
      formAttached: fkAwfFormId?.displayName || '',
      status: isPublished ? 'Published' : 'Draft',
      action: !permissions?.[approvalWorkflow]?.[permissionsObj.allowEdit] ? (
        { hide: true }
      ) : (
        <IconButton onClick={() => onDetailsClick(id)}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
    };
  });
  return (
    <>
      <CircularLoader show={loadingStatus === LoadingStatus.Loading} />
      {showForm ? (
        <AppWorkStr
          viewMode={viewMode}
          strucutreId={strucutreId}
          setViewMode={setViewMode}
          setShowForm={setShowForm}
        />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <FormHeader title="Approval Worklfow" />
          </Box>
          <ListView
            headCells={approvalFlowListHeader}
            rowsData={rowData}
            showSerialNo={true}
            showCheckbox={true}
            setCheckedItems={setCheckedItems}
            checkedItems={checkedItems}
            showAddIcon={permissions?.[approvalWorkflow]?.[permissionsObj.allowAdd]}
            onAddClick={() => onClickAddIcon()}
          />
        </Box>
      )}
    </>
  );
};

export default AllStructureAppwork;
