import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/VE_LOGO1.png';

const ThankYouPage = () => {
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f9f9f9',
    fontFamily: 'Arial, sans-serif',
  };

  const cardStyle = {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '700px',
    width: '90%',
  };

  const logoStyle = {
    width: '100px',
    marginBottom: '20px',
  };

  const titleStyle = {
    color: '#333',
    fontSize: '24px',
    marginBottom: '20px',
  };

  const messageStyle = {
    color: '#555',
    fontSize: '16px',
    marginBottom: '20px',
  };

  const noteStyle = {
    color: '#777',
    fontSize: '14px',
    marginBottom: '30px',
  };

  const linkStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '4px',
    fontSize: '16px',
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <img
          src={Logo}
          alt="Logo"
          style={logoStyle}
        />
        <h1 style={titleStyle}>Welcome Aboard!</h1>
        <p style={messageStyle}>
          Thank you for choosing <b>{'vExecution HCM'}</b>
        </p>
        <p style={messageStyle}>
          Please check your email for further instructions to ensure a smooth
          onboarding experience.
        </p>
        <p style={noteStyle}>
          You may reach out to our support team at
          <a
            href="mailto:obie@vexecution.com"
            style={{ color: '#007bff', textDecoration: 'none' }}
          >
            {' '}
            obie@vexecution.com{' '}
          </a>{' '}
          for any assistance.
        </p>
        <a href="https://vexecution.com" style={linkStyle}>
          Go to Homepage
        </a>
      </div>
    </div>
  );
};

export default ThankYouPage;
