import { Box, CircularProgress, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import { useStyles } from '../login/Login';
import veLOGO from '../../assets/VE_LOGO1.png';
import Logo from '../../assets/tenant-logo.png';
import { useParams, useSearchParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Joi from 'joi-browser';
import { mainServices } from '../../services/main';

export default function ResetPassword() {
    const [values, setValues] = useState({
        password: '',
        showPassword: false,
        confirmPassword:'',
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [searchParams] =  useSearchParams();
    const classes = useStyles();
    
    const validationSchema = {
        password: Joi.string().min(4).label('Password').required(), 
        confirmPassword: Joi.string().min(4).valid(values.password).label('Confirm Password').required().options({ language: { any: { allowOnly: 'must match password' } } }), 
    };

    const hostname = window.location.hostname;
    const tenant =
      hostname.split('.')[0] === 'localhost'
        ? process.env.REACT_APP_LOCALHOST_CLIENT_ID
        : hostname.split('.')[0];

    const handleSubmit = async ()=>{
        setSubmitted(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        setSuccess(false);
        const token = searchParams.get('token');
        console.log('token', token);
        const {error} = Joi.object(validationSchema).validate({
            password:values.password, 
            confirmPassword: values.confirmPassword
        });
        const err = {};
        if (error){
            error.details?.forEach((item)=>{
                err[item.context.key] = item.message;
            });
        }
        setError(err);
        // call the api
            try {
                const api = new mainServices();
                const data = await api.resetPassword({token,confirm_password: values.confirmPassword, password: values.password});
                console.log(data);
                setSuccess(true);
                setSuccessMessage('Password Reseted Successfully, \n Please login with new credentails');
            } catch (error) {
                console.log(error?.response?.data);
                setErrorMessage(error?.response?.data?.message || error.message);
            }
        setSubmitted(false);
    };
    const handleChange = (event)=>{
            const name = event.target.name;
            const value = event.target.value;
            setValues({...values,[name]: value});
            const {error: err} =  validationSchema[name].validate(value);
            console.log(err);
            if(err){
                const temp = {...error, [name]: err.message};
                setError(temp);
            }else {
                const temp = {...error};
                delete temp[name];
                setError(temp);
            }
    };
    const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
    };
    const redirectToLogin = ()=>{
        window.location.replace('/login');
    };
  return (
    <React.Fragment>
    <Grid container className="login-screen-wrap" style={{ padding: '5%' }}>
      <Grid item xs={12} sm={12} md={6} className="login-inputs-container">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            minHeight: '80vh',
            background: '#ffffff',
          }}
        >
          <Grid xs={11} sm={6} style={{}}>
            <Grid container>
              <h2 style={{ padding: '0.1em' }} className="client-logo">
                {/* <img src={} alt='NTUC'/> */}
                <img src={tenant === 'yts' ? Logo : veLOGO} alt="vExecution" />
              </h2>

              <Grid xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {!success ? <>
                        <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                        {errorMessage && (<Typography color={'red'}>
                        {errorMessage}
                        </Typography>)}

                    </Grid>
                    <Grid item xs={12}>
                      <label htmlFor="" style={{ fontSize: '15px' }}>
                        Password
                      </label>
                      <TextField
                        type="password"
                        required
                        variant="outlined"
                        size="small"
                        value={values.password}
                        name="password"
                        fullWidth
                        error={error.password ? true : false}
                        helperText={error.password || ''}
                        onChange={handleChange}
                        classes={classes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          classes={classes}
                        >
                          <label htmlFor="" style={{ fontSize: '15px' }}>
                            Confim Password
                          </label>
                          <TextField
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.confirmPassword}
                            name="confirmPassword"
                            onChange={handleChange} 
                            fullWidth
                            size="small"
                            classes={classes}
                            error={error.confirmPassword ? true : false}
                            helperText={error.confirmPassword || ''}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword} 
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    <Grid item xs={12}>
                      <ButtonUI
                        backgroundColor="#00ac94"
                        color="#ffffff"
                        onclick={handleSubmit}
                        width="100%"
                        className="login-btn"
                        disabled={submitted}
                      >
                        {submitted ? <CircularProgress style={{color: 'white'}} size={'30px'} /> : 'Reset Password'}
                        
                      </ButtonUI>
                    </Grid>
                    </> : 
                    <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                        {success && (<Typography color={'green'}>
                        {successMessage}
                        </Typography>)}

                    </Grid>
                    <Grid item xs={12}>
                      <ButtonUI
                        backgroundColor="#00ac94"
                        color="#ffffff"
                        onclick={redirectToLogin}
                        width="100%"
                        className="login-btn"
                        disabled={submitted}
                      >
                        Login Again
                      </ButtonUI>
                    </Grid>
                    </>}
                   
                    
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
  );
}
