import { isEqual } from 'lodash';
import { FormFieldTypes } from '../../../../../constants/common';
import {
  checkForValidDateForIsoFormat,
  currentDate,
  getDate,
  getDateTime,
  getFormattedDate,
  getFormattedValues,
  isAnyOfDays,
  isDateAfter,
  isDateBefore,
  isDateBetween,
  isDateSame,
  isDateTimeAfter,
  isDateTimeBefore,
  isDateTimeSame,
  isTimeAfter,
  isTimeBefore,
  isTimeSame,
  isTimeSameOrAfter,
  isTimeSameOrBefore,
  utcToLocal,
  utcToLocalMomentObj,
} from '../../../../../utils/formatter';
import { DateFormatters } from '../../../../../constants/dateConstants';

export const getDropdownListValue = (id, name) => ({ id, name });

export const getSubType = (subtype) => {
  if (subtype === FormFieldTypes.Number) {
    return 'number';
  } else if (subtype === FormFieldTypes.Email) {
    return 'email';
  }
  return subtype;
};

export const formConditionsMap = {
  isEqual: 'isEqual',
  isNotEqual: 'isNotEqual',
  between: 'isBetween',
  isAfter: 'isAfter',
  isBefore: 'isBefore',
  isGreaterThan: 'isGreaterThan',
  isGreaterThanAndEqual: 'isGreaterThanAndEqual',
  isLessThan: 'isLessThan',
  isLessThanAndEqual: 'isLessThanAndEqual',
  timeIsAfter: 'timeIsAfter',
  timeIsBefore: 'timeIsBefore',
  dateIs: 'dateIs',
  isNotEmtpy: 'isNotEmtpy',
  isAnyOfDays: 'isAnyOfDays',
  isPublicHoliday: 'isPublicHoliday',
  isAnyOf: 'isAnyOf',
  isNoneOf: 'isNoneOf'
};
const conjunctionTypes = {
  any: 'any',
  all: 'all',
};

export const getFormulaExpression = (
  formulaRules,
  formInfo,
  defaultExpression,
  publicHolidays
) => {
  let evalExpression = defaultExpression || null;
  if (formulaRules) {
    formulaRules.some((formula) => {
      const { conjunction, conditions, expression } = formula;
      if (readConditions(conditions, conjunction, formInfo, publicHolidays)) {
        evalExpression = expression;
        return true;
      }
    });
  }
  return evalExpression;
};

export const readConditions = (
  conditions,
  conjunction,
  formInfo,
  publicHolidays
) => {
  switch (conjunction) {
    case conjunctionTypes.all: {
      return conditions.every((item) => {
        const { component, operator, value, componentFieldType } = item;
        return evaluateCondition(
          getValue(formInfo?.[component], componentFieldType),
          operator,
          getTargetValue(value, formInfo, componentFieldType),
          componentFieldType,
          publicHolidays
        );
      });
    }
    case conjunctionTypes.any: {
      return conditions.some((item) => {
        const { component, operator, value, fieldType, componentFieldType } =
          item;
        return evaluateCondition(
          getValue(formInfo?.[component], componentFieldType),
          operator,
          getTargetValue(value, formInfo, componentFieldType),
          componentFieldType,
          publicHolidays
        );
      });
    }
    default:
      return false;
  }
};

export const evaluateCondition = (
  value,
  operator,
  targetValue,
  fieldType,
  publicHolidays
) => {
  switch (fieldType) {
    case FormFieldTypes.Date: {
      return compareDates(value, targetValue, operator, publicHolidays);
    }
    case FormFieldTypes.Time: {
      return compareTime(value, targetValue, operator);
    }
    case FormFieldTypes.DateTime: {
      return compareDateTime(value, targetValue, operator, publicHolidays);
    }
    default:
      return compareValues(value, targetValue, operator);
  }
};

const compareValues = (value, targetValue, operator) => {
  switch (operator) {
    case formConditionsMap.isEqual:
      return isEqual(value, targetValue);
    case formConditionsMap.isNotEqual:
      return !isEqual(value, targetValue);
    case formConditionsMap.between: {
      const [min, max] = targetValue;
      return value >= min && value <= max;
    }
    case formConditionsMap.isGreaterThan:
      return value > targetValue;
    case formConditionsMap.isGreaterThanAndEqual:
      return value >= targetValue;
    case formConditionsMap.isLessThan:
      return value < targetValue;
    case formConditionsMap.isLessThanAndEqual:
      return value <= targetValue;
    default:
      return false;
  }
};

const compareDates = (value, targetValue, operator, publicHolidays) => {
  switch (operator) {
    case formConditionsMap.isEqual:
      return isDateSame(value, targetValue);
    case formConditionsMap.isNotEqual:
      return !isDateSame(value, targetValue);
    case formConditionsMap.between:
      return isDateBetween(value, targetValue);
    case formConditionsMap.isAfter:
      return isDateAfter(value, targetValue);
    case formConditionsMap.isBefore:
      return isDateBefore(value, targetValue);
    case formConditionsMap.isAnyOfDays: {
      const daysList = targetValue.map((item) => item.id);
      const day = getDate(value, DateFormatters.YYYYMMDD).day();
      return isAnyOfDays(daysList, day);
    }
    case formConditionsMap.isPublicHoliday: {
      return publicHolidays.includes(value);
    }
    default:
      return false;
  }
};

const compareTime = (value, targetValue, operator) => {
  switch (operator) {
    case formConditionsMap.isEqual:
      return isTimeSame(value, targetValue);
    case formConditionsMap.isNotEqual:
      return !isTimeSame(value, targetValue);
    case formConditionsMap.isAfter:
      return isTimeAfter(value, targetValue);
    case formConditionsMap.isBefore:
      return isTimeBefore(value, targetValue);
    default:
      return false;
  }
};

const compareDateTime = (value, targetValue, operator, publicHolidays) => {
  switch (operator) {
    case formConditionsMap.isEqual: {
      const newValue = utcToLocal(
        value,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      const newTargetValue = utcToLocal(
        targetValue,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      return isDateTimeSame(newValue, newTargetValue);
    }
    case formConditionsMap.isNotEqual: {
      const newValue = utcToLocal(
        value,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      const newTargetValue = utcToLocal(
        targetValue,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      return !isDateTimeSame(newValue, newTargetValue);
    }
    case formConditionsMap.isAfter: {
      const newValue = utcToLocal(
        value,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      const newTargetValue = utcToLocal(
        targetValue,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      return isDateTimeAfter(newValue, newTargetValue);
    }
    case formConditionsMap.isBefore: {
      const newValue = utcToLocal(
        value,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      const newTargetValue = utcToLocal(
        targetValue,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      return isDateTimeBefore(newValue, newTargetValue);
    }
    case formConditionsMap.timeIsAfter: {
      const newTimeValue = utcToLocal(value, 'YYYY-MM-DD HH:mm', 'hh:mm a');
      return isTimeAfter(newTimeValue, targetValue);
    }
    case formConditionsMap.timeIsBefore: {
      const newTimeValue = utcToLocal(value, 'YYYY-MM-DD HH:mm', 'hh:mm a');
      return isTimeBefore(newTimeValue, targetValue);
    }
    case formConditionsMap.dateIs: {
      const newDateValue = utcToLocal(value, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD');
      return isDateSame(newDateValue, targetValue);
    }
    case formConditionsMap.isNotEmtpy:
      return value !== undefined;
    case formConditionsMap.isAnyOfDays: {
      const day = utcToLocalMomentObj(value, DateFormatters.YYYYMMDD24Hr).day();
      const daysList = targetValue.map((item) => item.id);

      return isAnyOfDays(daysList, day);
    }
    case formConditionsMap.isPublicHoliday: {
      const newValue = utcToLocal(
        value,
        DateFormatters.YYYYMMDD24Hr,
        DateFormatters.YYYYMMDD
      );
      return publicHolidays.includes(newValue);
    }
    default:
      return false;
  }
};

const getValue = (value, fieldType) => {
  switch (fieldType) {
    case FormFieldTypes.SingleSelect: {
      return value?.id;
    }
    case FormFieldTypes.Date: {
      return checkForValidDateForIsoFormat(value)
        ? value
        : getFormattedDate(
            value,
            DateFormatters.ddmmyyyy,
            DateFormatters.YYYYMMDD
          );
    }
    default:
      return value;
  }
};

const getTargetValue = (value, formInfo, fieldType) => {
  switch (fieldType) {
    case FormFieldTypes.Formula:
    case FormFieldTypes.Number: {
      return isNaN(value) ? formInfo?.[value] : value;
    }
    default:
      return value;
  }
};

export const RangeValueTypes = {
  DisabledFuture: 1,
  DisabledPast: 2,
  Range: 3,
};

export const DateRangeValidations = (
  value,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  name,
  fieldType
) => {
  let errors = {};
  switch (fieldType) {
    case FormFieldTypes.Date: {
      const minVal = getFormattedValues(DateFormatters.ddmmyyyy, minDate);
      const maxVal = getFormattedValues(DateFormatters.ddmmyyyy, maxDate);
      const currentVal = getFormattedValues(
        DateFormatters.ddmmyyyy,
        currentDate
      );
      const momentObj = checkForValidDateForIsoFormat(value)
        ? value
        : getDate(value, DateFormatters.ddmmyyyy);

      if (minDate && isDateBefore(momentObj, minDate)) {
        errors = { ...errors, [name]: `Select Date On or After ${minVal}` };
      } else if (maxDate && isDateAfter(momentObj, maxDate)) {
        errors = { ...errors, [name]: `Select Date On or Before ${maxVal}` };
      } else if (disableFuture && isDateAfter(momentObj, currentDate)) {
        errors = {
          ...errors,
          [name]: `Select Date On or Before ${currentVal}`,
        };
      } else if (disablePast && isDateBefore(momentObj, currentDate)) {
        errors = { ...errors, [name]: `Select Date On or After ${currentVal}` };
      } else {
        errors = {};
      }
      break;
    }
    case FormFieldTypes.DateTime: {
      const minVal = getFormattedValues('DD/MM/YYYY hh:mm a', minDate);
      const maxVal = getFormattedValues('DD/MM/YYYY hh:mm a', maxDate);
      const currentVal = getFormattedValues('DD/MM/YYYY hh:mm a', currentDate);
      const newValue = utcToLocal(
        value,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:mm a'
      );
      const momentObj = getDateTime(newValue, 'YYYY-MM-DD hh:mm a');

      if (minDate && momentObj.isSameOrBefore(minDate)) {
        errors = {
          ...errors,
          [name]: `Select Date/Time After ${minVal}`,
        };
      } else if (maxDate && momentObj.isSameOrAfter(maxDate)) {
        errors = {
          ...errors,
          [name]: `Select Date/Time Before ${maxVal}`,
        };
      } else if (disableFuture && momentObj.isSameOrAfter(currentDate)) {
        errors = {
          ...errors,
          [name]: `Select Date/Time Before ${currentVal}`,
        };
      } else if (disablePast && momentObj.isSameOrBefore(currentDate)) {
        errors = {
          ...errors,
          [name]: `Select Date/Time After ${currentVal}`,
        };
      } else {
        errors = {};
      }
      break;
    }
    case FormFieldTypes.Time: {
      const minVal = getFormattedValues('hh:mm a', minDate);
      const maxVal = getFormattedValues('hh:mm a', maxDate);
      const currentVal = getFormattedValues('hh:mm a', currentDate);
      const momentObj = getDateTime(value, 'hh:mm a');

      if (minDate && isTimeSameOrBefore(momentObj, minDate)) {
        errors = { ...errors, [name]: `Select Time After ${minVal}` };
      } else if (maxDate && isTimeSameOrAfter(momentObj, maxDate)) {
        errors = { ...errors, [name]: `Select Time Before ${maxVal}` };
      } else if (disableFuture && isTimeSameOrAfter(momentObj, currentDate)) {
        errors = {
          ...errors,
          [name]: `Select Time Before ${currentVal}`,
        };
      } else if (disablePast && isTimeSameOrBefore(momentObj, currentDate)) {
        errors = { ...errors, [name]: `Select Time After ${currentVal}` };
      } else {
        errors = {};
      }
      break;
    }

    default: {
      errors = {};
      break;
    }
  }
  return errors;
};

export const checkForZero = (fieldRefName, isHidden, formInfo) => {
  if (
    fieldRefName === 'amount' &&
    !isHidden &&
    Number(formInfo['amount']) === 0
  ) {
    return 'Final/Total Amount Cannot be Zero';
  }
  return '';
};

export const NumberValidation = (type, value, inputValue, fieldRefName) => {
  switch (type) {
    case formConditionsMap.isGreaterThan:
      if (value >= inputValue) {
        return {
          [fieldRefName]: `Enter a value greater than ${value}.`,
        };
      }
      break;
    case formConditionsMap.isGreaterThanAndEqual:
      if (value > inputValue) {
        return {
          [fieldRefName]: `Enter a value greater than or equal to ${value}.`,
        };
      }
      break;
    case formConditionsMap.isLessThan:
      if (value <= inputValue) {
        return {
          [fieldRefName]: `Enter a value less than ${value}.`,
        };
      }
      break;
    case formConditionsMap.isLessThanAndEqual:
      if (value < inputValue) {
        return {
          [fieldRefName]: `Enter a value less than or equal to ${value}.`,
        };
      }
      break;
    case formConditionsMap.isEqual:
      if (!isEqual(value, inputValue)) {
        return {
          [fieldRefName]: `Enter a value equal to ${value}.`,
        };
      }
      break;
    case formConditionsMap.isNotEqual:
      if (isEqual(value, inputValue)) {
        return {
          [fieldRefName]: `Enter a value not equal to ${value}.`,
        };
      }
      break;
    default:
      return {};
  }
};
