import React from 'react';
import { Box, Button } from '@mui/material';
import ListView from '../../../atoms/DataList/ListView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { getAllUsers, getRolesList } from '../../../redux/onboarding/action';
import {
  FormFieldNames,
  ToastMessages,
  URLSearchParams,
  allowActivate,
  documents,
  educationHistory,
  employeeHistory,
  payrollAssignment,
  personalDetails,
  seperation,
  stringSubstitute,
  userInfo,
  workDetails,
} from '../Organization/constants/onboarding.constants';
import EmpDataCard from '../../common/EmpDataCard/empDataCard';
import {
  getCommaSeparatedStringFromList,
  showToast,
} from '../../../utils/common.util';
import {
  getUserDetails,
  getUserDocumentsList,
  getUserOnboardingMenu,
  resetUserInfo,
  updateUserInfo,
  updateUserStatus,
} from '../../../redux/userOnboarding/action';
import { useNavigate, useOutletContext } from 'react-router';
import { getDropdownListValue } from '../common/Form/util/form.util';
import TabHead from '../../../atoms/Tabs/TabHead';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import ModalDialog from '../../../atoms/Modal/ModalDialog';
import UserDocumentsList from './UserDocumentList';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../constants/common';
import {
  UserStatus,
  allUserHeadCells,
} from '../Organization/utils/userOnboarding/userOnboarding.util';
import ButtonDropdown from '../../../atoms/ButtonDropdown/button-dropdown.component';
import TryAgain from '../../molecules/ErrorState/error.component';
import { permissionsObj } from '../Organization/RolesAndPermissions/constants/roles-permissions.constants';
import ChipTag from '../../../atoms/ChipTag/ChipTag';
import { DateRangePro } from '../../molecules/DateRange/DateRangePro';
import { useDebounce } from 'usehooks-ts';
import { getFormattedDate, getFormattedValues } from '../../../utils/formatter';
import { SettingsBackupRestoreOutlined } from '@mui/icons-material';
import { DateFormatters } from '../../../constants/dateConstants';

export default function AllUsers() {
  const dispatch = useDispatch();
  const {
    users,
    rolesList,
    userIdPropertiesMap,
    isHrOD,
    tntOwnerRoleId,
    requestProcessing: {
      [APIRequestStatus.GET_ROLES_LIST]: rolesListLoading,
      [APIRequestStatus.GET_ALL_USERS]: usersListLoading,
    },
  } = useSelector((state) => state.onBoarding);
  const {
    userInfo: { data: formData, id, isPublished },
    userDocumentsList,
    onboardingUserMenu,
    requestProcessing: {
      [APIRequestStatus.GET_USER_ONBOARDING_MENU]: onboardingMenuLoading,
    },
  } = useSelector((state) => state.userOnboarding);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { userProfilePermissions, myProfilePermissions } = useSelector(
    (state) => state.modulePermissions
  );

  const [viewMode, setViewMode] = useOutletContext();
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [documentLoading, setDocumentLoading] = useState(-1);
  const [userStatusLoading, setUserStatusLoading] = useState(-1);
  const [checkedItems, setCheckedItems] = useState([]);
  const [dropdownMenuList, setDropdownMenuList] = useState([]);
  const [searchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(
        getAllUsers(
          currentUserRoleId,
          searchQuery,
          selectedDate[0]
            ? getFormattedValues('YYYY-MM-DD', selectedDate[0])
            : null,
          selectedDate[1]
            ? getFormattedValues('YYYY-MM-DD', selectedDate[1])
            : null
        )
      );
    }
  }, [currentUserRoleId, selectedDate, debouncedVal]);

  useEffect(() => {
    if (isEmpty(rolesList)) {
      dispatch(getRolesList());
    }
    dispatch(getUserOnboardingMenu());
  }, [currentUserRoleId]);

  useEffect(() => {
    setCheckedItems([]);
  }, [users]);

  useEffect(() => {
    const activateDisabled = !!checkedItems.find(
      (item) => userIdPropertiesMap?.[item].statusCode === UserStatus.Activate
    );
    const deactivateDisabled = !!checkedItems.find(
      (item) => userIdPropertiesMap?.[item].statusCode !== UserStatus.Activate
    );
    const hideOption = checkedItems.some(
      (item) => userIdPropertiesMap?.[item]?.roleId?.includes(Number(tntOwnerRoleId))
    );
    const menuList = dropdownMenuList.map((item) => {
      switch (item.statusCode) {
        case UserStatus.Activate: {
          return { ...item, disabled: activateDisabled };
        }
        case UserStatus.Deactivate: {
          return { ...item, disabled: deactivateDisabled, hide: hideOption };
        }
        default:
          return item;
      }
    });
    setDropdownMenuList([...menuList]);
  }, [checkedItems]);

  useEffect(() => {
    setDropdownMenuList(
      onboardingUserMenu.map((item) => ({
        id: item.id,
        name: item.statusName,
        statusCode: item.statusCode,
        disabled: false,
      }))
    );
  }, [onboardingUserMenu]);

  const roles = rolesList.map((role) => {
    const { id, role_name } = role;
    return getDropdownListValue(id, role_name);
  });

  const handleAddClick = () => {
    setViewMode(false);
    navigate('add-user');
    dispatch(resetUserInfo());
  };

  const handleInfoClick = (id) => {
    navigate({ pathname: `${id}` });
    setViewMode(true);
  };

  const handleOptionClick = (statusId) => {
    setUserStatusLoading(LoadingStatus.Loading);
    dispatch(
      updateUserStatus({
        userIds: checkedItems.map(Number),
        employeeStatus: statusId,
      })
    )
      .then((success) => {
        if (success) {
          dispatch(
            getAllUsers(
              currentUserRoleId,
              searchQuery,
              selectedDate[0]
                ? getFormattedValues('YYYY-MM-DD', selectedDate[0])
                : null,
              selectedDate[1]
                ? getFormattedValues('YYYY-MM-DD', selectedDate[1])
                : null
            )
          );
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.update
            ),
            ToastThemes.success
          );
          setUserStatusLoading(LoadingStatus.Success);
          setshowModal(false);
        }
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
        setUserStatusLoading(LoadingStatus.Failure);
      });
  };

  const isDisabledCheckbox = (item) => {
    return item.statusCode.value === UserStatus.Draft;
  };

  const resetFilters = () => {
    setSelectedDate([null, null]);
  };

  const rowData = users
    .filter((user) => {
      const { roles } = user;
      const roleIds = roles.map((role) => role.id);
      return selectedRole ? roleIds.includes(selectedRole) : user;
    })
    .map((user) => {
      const {
        firstName,
        id,
        roles,
        department,
        companyEmail,
        updatedAt,
        profileLogo,
        documentsMapped,
        employeeStatus,
        dateOfJoining,
        employeeId,
        createdAt,
      } = user;
      return {
        name: (
          <EmpDataCard
            name={firstName}
            email={companyEmail}
            url={profileLogo?.url}
          />
        ),
        [FormFieldNames.EmployeeId]: employeeId,
        [FormFieldNames.DateOfJoining]: isHrOD? { hide: true } : dateOfJoining
          ? getFormattedDate(
              dateOfJoining,
              DateFormatters.ddmmyyyy,
              DateFormatters.yyyymmdd
            )
          : '',
        [FormFieldNames.UpdatedAt]: isHrOD? { hide: true } : updatedAt
          ? getFormattedDate(
              updatedAt,
              DateFormatters.ddmmyyyy,
              DateFormatters.yyyymmdd
            )
          : '',
        [FormFieldNames.Roles]: isHrOD? { hide: true } : <ChipTag values={roles} />,
        [FormFieldNames.Departments]: department?.name,
        status: isHrOD? { hide: true } : employeeStatus?.statusName || '',
        createdAt: isHrOD? { hide: true } : createdAt
          ? getFormattedDate(
              createdAt,
              DateFormatters.YYYYMMDD24Hr,
              DateFormatters.yyyymmdd
            )
          : '',
        icon: (
          <>
            <InfoOutlinedIcon
              sx={{ color: 'primary.main80' }}
              onClick={() => handleInfoClick(id)}
            />
          </>
        ),
        id: {
          hide: true,
          value: id,
        },
        statusCode: {
          hide: true,
          value: employeeStatus?.statusCode,
        },
      };
    });

  if (usersListLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  } else {
    return (
      <Box mt={4}>
        <>
          <CircularLoader
            show={
              rolesListLoading === LoadingStatus.Loading ||
              usersListLoading === LoadingStatus.Loading ||
              documentLoading === LoadingStatus.Loading ||
              onboardingMenuLoading === LoadingStatus.Loading ||
              userStatusLoading === LoadingStatus.Loading
            }
          />
          {!isHrOD && (
            <TabHead
              filterDataRoles={roles}
              placeholder="Search Role"
              onClickHeader={(id) => setSelectedRole(id)}
              onClickClear={() => setSelectedRole('')}
            />
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
            bgcolor="#ffffff"
            p={2}
          >
            {!isHrOD && (
              <>
                <DateRangePro
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
                <Button
                  startIcon={<SettingsBackupRestoreOutlined />}
                  sx={{ textTransform: 'capitalize' }}
                  onClick={resetFilters}
                >
                  Clear
                </Button>
              </>
            )}
            {userProfilePermissions?.[allowActivate]?.[
              permissionsObj.allowView
            ] ? (
              <Box sx={{ float: 'right', mr: 1 }}>
                <ButtonDropdown
                  options={dropdownMenuList}
                  onOptionClick={handleOptionClick}
                  disabled={!checkedItems.length}
                  checkedItems={checkedItems}
                  showModal={showModal}
                  setshowModal={setshowModal}
                />
              </Box>
            ) : null}
          </Box>
          <ListView
            headCells={allUserHeadCells(isHrOD)}
            rowsData={rowData}
            onAddClick={handleAddClick}
            setCheckedItems={setCheckedItems}
            checkedItems={checkedItems}
            isDisabledCheckbox={(item) => isDisabledCheckbox(item)}
            showAddIcon={
              userProfilePermissions[userInfo]?.[permissionsObj.allowAdd] ||
              userProfilePermissions[personalDetails]?.[
                permissionsObj.allowAdd
              ] ||
              userProfilePermissions[workDetails]?.[permissionsObj.allowAdd] ||
              userProfilePermissions[documents]?.[permissionsObj.allowAdd] ||
              userProfilePermissions[employeeHistory]?.[
                permissionsObj.allowAdd
              ] ||
              userProfilePermissions[educationHistory]?.[
                permissionsObj.allowAdd
              ] ||
              userProfilePermissions[seperation]?.[permissionsObj.allowAdd]
            }
            showCheckbox
            addDisabled={
              !(
                userProfilePermissions[userInfo]?.[permissionsObj.allowAdd] ||
                userProfilePermissions[personalDetails]?.[
                  permissionsObj.allowAdd
                ] ||
                userProfilePermissions[workDetails]?.[
                  permissionsObj.allowAdd
                ] ||
                userProfilePermissions[documents]?.[permissionsObj.allowAdd] ||
                userProfilePermissions[employeeHistory]?.[
                  permissionsObj.allowAdd
                ] ||
                userProfilePermissions[educationHistory]?.[
                  permissionsObj.allowAdd
                ] ||
                userProfilePermissions[seperation]?.[permissionsObj.allowAdd] ||
                userProfilePermissions?.[payrollAssignment]?.[
                  permissionsObj.allowAdd
                ]
              )
            }
          />
        </>
      </Box>
    );
  }
}
