import React, { useEffect, useMemo, useState } from 'react';
import FormEdit from '../../../../OnBoarding/common/Form/FormEdit';
import { Box } from '@mui/material';
import { BuilderServices } from '../../../../../services/builder';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClaimForm,
  postClaimInfo,
} from '../../../../../redux/form-builder/action';
import { showToast } from '../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import {
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../../constants/common';
import { useNavigate } from 'react-router';
import {
  getFormBody,
  getMandatoryFields,
  getValidationErrors,
} from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty, isNaN } from 'lodash';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import {
  ClaimTypeCode,
  fieldNamesTeamBuilding,
} from '../../../../form-builder/utils/team-building-utils';
import StyledButton from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader.styled';
import {
  AddOutlined,
  CalculateOutlined,
  ContentCopyOutlined,
} from '@mui/icons-material';
import {
  UnergizerFieldConfig,
  initialVal,
  totalForLimit,
} from '../unergizer-claim-form/utils/unergizer-utils';
import { NewClaimsService } from '../../../../../services/newClaims';
import { approvalWorkflowServices } from '../../../../../services/approvalWokflowService';
import {
  resetUnergizerErros,
  setUnergizerErros,
} from '../../../../../redux/newClaims/action';
import { getDate, isDateAfter, isDateBefore } from '../../../../../utils/formatter';

const ClaimEditForm = (props) => {
  const {
    formConfig,
    claimTypeData,
    claimFormData,
    setViewMode,
    claimTypeId,
    claimId,
    allowEdit,
    enitilements,
    resubmittedClaim,
    validationErrors,
    setValidationErrors,
    departementFomdata,
    setTeamBuildingData,
    benefitId,
    isMultiDepartment,
    setDepartementFomdata,
    setDepartementDropDown,
    setBenefits,
    setUnergizerFormData,
    unergizerFormData,
    isUnergizer,
    allowCopayment,
    singleDeptAllowed,
  } = props;

  const { referenceNumber, createdBy, coPaymentAmt, ...rest } = claimFormData;
  const [formState, setFormState] = useState(rest || {});
  const [filteredAtendeesList, setFilteredAtendeesList] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState({});
  const [attendeesList, setAttendeesList] = useState([]);
  const [loading, setLoading] = useState(-1);

  const { currentUserRoleId, userInfo } = useSelector((state) => state.main);
  const { publicHoliday } = useSelector((state) => state && state.onBoarding);
  const { entitlement } = useSelector((state) => state.formBuilder);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFormState(rest);
  }, [claimFormData]);

  useEffect(() => {
    if (allowCopayment) {
      setFormState((prev) => ({
        ...prev,
        coPaymentAmt: coPaymentAmt ? coPaymentAmt : entitlement.coPaymentAmt,
      }));
    }
  }, [entitlement]);

  useEffect(() => {
    if (isMultiDepartment) {
      setTeamBuildingData(() => {
        return {
          attendees: formState?.attendees?.map((item) => item.id),
          receiptAmount: formState?.receiptAmount,
        };
      });
    }
  }, [formState?.attendees, formState?.receiptAmount]);

  useEffect(() => {
    if (isMultiDepartment) {
      const departmentIds = departementFomdata?.map(
        (item) => item?.department?.id
      );
      const attendees = attendeesList.filter(
        (item) =>
          departmentIds.includes(item.departmentId) &&
          item.fkBenefitId === benefitId
      );
      const attendeesListIds = attendees?.map((item) => item?.id);
      let totalAmount = 0;
      const listOfCalculatedBalance = departementFomdata?.map(
        (item) => item?.computedAmount
      );
      for (const bal of listOfCalculatedBalance) {
        totalAmount += Number(bal);
      }
      if (attendeesListIds.length) {
        setFormState((prev) => {
          const data = { ...prev };
          if (fieldNamesTeamBuilding.Attendees_List in data) {
            data[fieldNamesTeamBuilding.Attendees_List] = data[
              fieldNamesTeamBuilding.Attendees_List
            ].filter((item) => attendeesListIds.includes(item.id));
          }
          if (totalAmount) {
            data['amount'] = totalAmount;
          }
          return data;
        });
      }

      setFilteredAtendeesList(attendees);
    }
  }, [departementFomdata, isMultiDepartment, attendeesList]);

  useEffect(() => {
    if (isUnergizer) {
      let totalLimits = Number(totalForLimit(unergizerFormData));
      setFormState((prev) => {
        const data = { ...prev };
        const finalAmount =
          totalLimits < data['receiptAmount']
            ? totalLimits
            : data['receiptAmount'];
        return {
          ...data,
          amount: finalAmount,
          computedAmount: Number(totalLimits.toFixed(2)),
        };
      });
    }
  }, [isUnergizer, unergizerFormData]);

  const getDropdownOptions = (name, id) => {
    switch (name) {
      case fieldNamesTeamBuilding.Attendees_List: {
        if (isMultiDepartment) {
          return filteredAtendeesList;
        }
        return attendeesList.filter((item) => item.fkBenefitId === benefitId);
      }
      default: {
        return dropDownOptions[name];
      }
    }
  };

  const handleDropdownClick = async (name, id) => {
    switch (name) {
      case fieldNamesTeamBuilding.Attendees_List: {
        return new BuilderServices().getDropDownOption(id).then((res) => {
          setAttendeesList(res);
          return true;
        });
      }
      default: {
        return new BuilderServices().getDropDownOption(id).then((res) => {
          setDropDownOptions({ ...dropDownOptions, [name]: res });
          return true;
        });
      }
    }
  };

  const removeAutoPopulatedFields = () => {
    const updatedFormdata = { ...formState };
    formConfig.forEach((field) => {
      if (
        (field.fieldType === FormFieldTypes.AutoPopulate ||
          field.fieldType === FormFieldTypes.ProRateField) &&
        field.fieldRefName in updatedFormdata
      ) {
        delete updatedFormdata[field.fieldRefName];
      }
    });
    return updatedFormdata;
  };

  const unergizerFormRequiredFields = useMemo(() => {
    return getMandatoryFields(UnergizerFieldConfig);
  }, [UnergizerFieldConfig]);

  const getUnergizerErrors = () => {
    const unergizerError = [];
    unergizerFormData.forEach((formData) => {
      const errors = getValidationErrors(formData, unergizerFormRequiredFields);
      unergizerError.push(errors);
    });
    return unergizerError;
  };

  const handleDraftClick = () => {
    const newFormData = removeAutoPopulatedFields();
    const claimConfig = getFormBody({ ...newFormData, status: 'DRAFT' });
    const { createdAt, ...claimConfigRest } = claimConfig;
    if (isUnergizer) {
      const unergizerErrors = getUnergizerErrors();
      const unergizerErrorIndex = unergizerErrors.findIndex(
        (error) => !isEmpty(error)
      );
      if (unergizerErrorIndex !== -1) {
        dispatch(setUnergizerErros(unergizerErrors));
        return;
      } else {
        dispatch(resetUnergizerErros());
      }
    }
    setLoading(LoadingStatus.Loading);
    const processUnergizer = async (res) => {
      if (res.id && isUnergizer) {
        try {
          await new NewClaimsService().postUnergizer({
            claimId: res.id,
            unergizerData: unergizerFormData,
          });
        } catch (err) {
          showToast(err?.response?.data?.message[0], ToastThemes.error);
          await new approvalWorkflowServices().deleteDraftClaims(
            res.id,
            claimTypeId,
            'clm_claim_register'
          );
          const { id, referenceNumber, status, createdBy, createdAt, ...rest } =
            formState;
          setFormState(rest);
          throw err?.response?.data?.message;
        }
      }
    };
    dispatch(
      postClaimInfo(
        claimTypeData,
        claimConfigRest,
        currentUserRoleId,
        isMultiDepartment
          ? departementFomdata?.filter((item) => item?.department?.id)
          : undefined
      )
    )
      .then(async (res) => {
        await processUnergizer(res);
        setViewMode(true);
        setLoading(LoadingStatus.Success);
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.success),
          ToastThemes.success
        );
        dispatch(getClaimForm(claimTypeId, currentUserRoleId, res.id));
        res.id &&
          navigate(`/custom-claim/${claimTypeId}/${res.id}`, { replace: true });
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(err, ToastThemes.error);
      });
  };

  const checkForEntitlements = async (entitleMents, formState) => {
    let errors = '';
    let checkFlag = false;
    let dateError = '';
    if (!isEmpty(entitleMents)) {
      if ('amount' in formState) {
        if (Number(entitleMents.entitlementBalanceAmt) === 0) {
          errors = 'Entitlement Balance Amount is Zero';
        } else if ('receiptAmount' in formState) {
          errors = '';
        } else {
          checkFlag =
            Number(formState['amount']) <=
            Number(entitleMents.entitlementBalanceAmt);
          if (!checkFlag && Number(entitleMents.entitlementBalanceAmt)) {
            errors = 'Amount is greater than the balance amount';
          }
        }
      } else {
        errors =
          'Amount field needs to be configured in the form builder for Enitilements to work';
      }
      if (
        ('receiptDate' in formState || 'claimDate' in formState) &&
        !entitleMents.fkBenefitId?.isFixedEntitlement
      ) {
        const receiptDate = getDate(formState['receiptDate']);
        const claimDate = getDate(formState['claimDate']);
        if (
          isDateAfter(receiptDate, entitleMents.endDate) ||
          isDateAfter(claimDate, entitleMents.endDate)
        ) {
          dateError = 'Select Date On or Before Entitlement End Date';
        } else if (
          isDateBefore(receiptDate, entitleMents.startDate) ||
          isDateBefore(claimDate, entitleMents.startDate)
        ) {
          dateError = 'Select Date On or After Entitlement Start Date';
        }
        else {
          dateError = '';
        }
      }
    }
    return { entitleMentsErrors: errors, checkFlag: checkFlag, dateError };
  };

  const checkForIsMultiDepartment = () => {
    if (
      claimTypeData?.claimTypeCode !==
        ClaimTypeCode.DepartmentUnionAssociationGatheringClaim &&
      !singleDeptAllowed &&
      isMultiDepartment &&
      departementFomdata?.filter((item) => item?.department?.id).length < 2
    ) {
      return 'Please select two or more department for multi department claim';
    }
    return '';
  };

  const handleSaveClick = async () => {
    const multiDepartmentCheck = checkForIsMultiDepartment();
    if (multiDepartmentCheck) {
      showToast(multiDepartmentCheck, ToastThemes.error);
      return;
    }
    const { entitleMentsErrors, checkFlag, dateError } =
      await checkForEntitlements(entitlement, formState);

    if (entitleMentsErrors) {
      showToast(entitleMentsErrors, ToastThemes.error);
      return;
    }
    if (dateError) {
      showToast(dateError, ToastThemes.error);
      return;
    }
    if (isUnergizer) {
      const unergizerErrors = getUnergizerErrors();
      const unergizerErrorIndex = unergizerErrors.findIndex(
        (error) => !isEmpty(error)
      );
      if (unergizerErrorIndex !== -1) {
        dispatch(setUnergizerErros(unergizerErrors));
        return;
      } else {
        dispatch(resetUnergizerErros());
      }
    }
    const res = await postClaim();
    setViewMode(true);
    dispatch(
      getClaimForm(
        claimTypeId,
        currentUserRoleId,
        !isNaN(claimId) ? claimId : res.id
      )
    );
    isNaN(claimId) &&
      navigate(`/new-claims/${claimTypeId}/${res.id}`, {
        replace: true,
      });
  };

  const handleSaveAndAddNewClick = async () => {
    const multiDepartmentCheck = checkForIsMultiDepartment();
    if (multiDepartmentCheck) {
      showToast(multiDepartmentCheck, ToastThemes.error);
      return;
    }
    const { entitleMentsErrors, checkFlag, dateError } =
      await checkForEntitlements(entitlement, formState);

    if (entitleMentsErrors) {
      showToast(entitleMentsErrors, ToastThemes.error);
      return;
    }
    if (dateError) {
      showToast(dateError, ToastThemes.error);
      return;
    }

    if (isUnergizer) {
      const unergizerErrors = getUnergizerErrors();
      const unergizerErrorIndex = unergizerErrors.findIndex(
        (error) => !isEmpty(error)
      );
      if (unergizerErrorIndex !== -1) {
        dispatch(setUnergizerErros(unergizerErrors));
        return;
      } else {
        dispatch(resetUnergizerErros());
      }
    }
    await postClaim();
    setFormState({});
    setBenefits(benefitId, claimId);
    if (isUnergizer) {
      setUnergizerFormData([initialVal]);
    }
    if (allowCopayment) {
      setFormState({ coPaymentAmt: '0.00' });
    }
    if (isMultiDepartment) {
      setDepartementFomdata([{}]);
      setDepartementDropDown([]);
    }
  };

  const handleSaveAndAddCopyClick = async () => {
    const multiDepartmentCheck = checkForIsMultiDepartment();
    if (multiDepartmentCheck) {
      showToast(multiDepartmentCheck, ToastThemes.error);
      return;
    }
    const { entitleMentsErrors, checkFlag, dateError } =
      await checkForEntitlements(entitlement, formState);

    if (entitleMentsErrors) {
      showToast(entitleMentsErrors, ToastThemes.error);
      return;
    }
    if (dateError) {
      showToast(dateError, ToastThemes.error);
      return;
    }

    await postClaim();
    setBenefits(benefitId, claimId);
    const { id, referenceNumber, status, createdBy, createdAt, ...rest } =
      formState;
    setFormState(rest);
  };

  const postClaim = async () => {
    try {
      const newFormData = removeAutoPopulatedFields();
      const claimConfig = getFormBody({ ...newFormData, status: 'PENDING' });
      const { createdAt, ...claimConfigRest } = claimConfig;
      setLoading(LoadingStatus.Loading);
      const payloadData = {
        claimConfig: claimConfigRest,
        claim: { id: claimTypeId },
        currentUserRoleId,
        allowEdit,
        resubmittedClaim,
        entitlement: entitlement.id,
      };
      if (isMultiDepartment) {
        Object.assign(payloadData, {
          departments: departementFomdata?.filter(
            (item) => item?.department?.id
          ),
        });
      }
      const res = await new BuilderServices().postSavedClaim(payloadData);
      if (res.id && isUnergizer) {
        try {
          await new NewClaimsService().postUnergizer({
            claimId: res.id,
            unergizerData: unergizerFormData,
          });
        } catch (err) {
          showToast(err?.response?.data?.message[0], ToastThemes.error);
          await new approvalWorkflowServices().deleteDraftClaims(
            res.id,
            claimTypeId,
            'clm_claim_register'
          );
          const { id, referenceNumber, status, createdBy, createdAt, ...rest } =
            formState;
          setFormState(rest);
          throw err?.response?.data?.message[0];
        }
      }
      setLoading(LoadingStatus.Success);
      showToast(
        ToastMessages.success.replace(stringSubstitute, toastMessage.success),
        ToastThemes.success
      );
      return res;
    } catch (err) {
      setLoading(LoadingStatus.Failure);
      showToast(err?.response?.data?.message, ToastThemes.error);
      throw err?.response?.data?.message;
    }
  };

  const handleDiscard = () => {
    if (isNaN(claimId)) {
      navigate(`/new-claims/${claimTypeId}`);
    } else {
      setViewMode(true);
    }
  };

  return (
    <Box>
      <CircularLoader show={LoadingStatus.Loading === loading} />
      <FormEdit
        list={formConfig}
        formInfo={formState}
        setFormData={setFormState}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
        errors={validationErrors}
        setErrors={setValidationErrors}
        isActionHeader={true}
        publicHoliday={publicHoliday}
        calculate={(onClick) =>
          claimTypeData?.claimTypeCode === 'SC' &&
          (createdBy === undefined || createdBy?.id === userInfo?.id) &&
          (formState?.typeOfCommision?.name === 'Quarterly' ||
            formState?.typeOfCommision?.name === 'Yearly') && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                mr: 3,
                mt: 1,
              }}
            >
              <StyledButton onClick={onClick}>
                <CalculateOutlined
                  sx={{ width: '24px', height: '24px', mr: '5px' }}
                />
                Calculate Amount
              </StyledButton>
            </Box>
          )
        }
        actionHeader={{
          labelText: claimTypeData?.name || 'New Claim',
          showDiscard: true,
          showPublish: true,
          showPublishModal: false,
          publishButtonText: 'Submit',
          onPublishClick: handleSaveClick,
          editButtonText: 'Save As Draft',
          showSave:
            formState?.status === 'DRAFT' || !formState?.status || false,
          onEditClick: handleDraftClick,
          onClickDiscard: handleDiscard,
          labelCode: referenceNumber || '',
          handleSaveAndAddNewClick: handleSaveAndAddNewClick,
          handleSaveAndAddCopyClick: handleSaveAndAddCopyClick,
          disabledPublish:
            claimTypeData?.claimTypeCode === 'SC' &&
            (formState?.typeOfCommision?.name === 'Quarterly' ||
              formState?.typeOfCommision?.name === 'Yearly') &&
            formState?.prorateFactorPreviousYear === null &&
            formState?.prorateFactorPreviousQuarter === null,
          firstButton: (onClick) =>
            !isMultiDepartment &&
            !isUnergizer &&
            !allowCopayment &&
            claimTypeData?.claimTypeCode !== 'SC' &&
            isNaN(claimId) && (
              <StyledButton onClick={onClick}>
                <ContentCopyOutlined
                  sx={{ width: '24px', height: '24px', mr: '5px' }}
                />
                Submit & Copy
              </StyledButton>
            ),
          secondButton: (onClick) =>
            isNaN(claimId) &&
            !isUnergizer &&
            claimTypeData?.claimTypeCode !== 'SC' && (
              <StyledButton onClick={onClick}>
                <AddOutlined
                  sx={{ width: '24px', height: '24px', mr: '5px' }}
                />
                Submit & New
              </StyledButton>
            ),
        }}
      />
    </Box>
  );
};

export default ClaimEditForm;
